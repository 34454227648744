<template>
  <div>
    <loading :loading="loading" v-if="loading"/>

    <template v-if="encrypted_data && this.is_decryptable_in_browser">

      <b-form-group id="input-group-organization" label="Versleutelde gegevens:" label-for="input-2">
        <b-tabs>
          <b-tab title="Volledige data">
            <b-textarea onclick="  document.execCommand('copy');"
              v-model="encrypted_data"
              disabled="disabled"
            ></b-textarea>
            </b-tab>
          <b-tab title="Data zonder nonce (voor tweetnacl)" lazy>
            <b-textarea onclick="  document.execCommand('copy');"
              :value="get_message()"
              disabled="disabled"
            >{{get_message()}}</b-textarea>
            </b-tab>
          <b-tab title="Nonce (voor tweetnacl)">
            <b-textarea onclick="  document.execCommand('copy');"
              :value="get_nonce()"
              disabled="disabled"
            ></b-textarea>
            </b-tab>
        </b-tabs>
      </b-form-group>

      <b-alert show variant="warning">
        {{ $t('decryption_in_browser_warning') }}
      </b-alert>

      <b-form-group
      id="fieldset-1"
      label="Private Key (do not share this with anyone, this is not saved)"
      label-for="private_key"
    >
      <b-input v-model="private_key"  id="private_key" type="password" trim class="mb-2"/>

      <b-button size="sm" variant="success" @click="decrypt_report()" ><b-icon icon="key" /> Ontsleutelen in browser</b-button>
    </b-form-group>

    </template>

    <template v-if="show_decription_error">
      <b-alert variant="danger" show>{{ $t('could_not_decrypt_data') }}</b-alert>
    </template>

    <template v-if="decrypted_data">

      <h2>{{$t('decrypted_data')}}</h2>

      <PrettyOutput
        :extension="extension"
        :data="decrypted_data"
      ></PrettyOutput>

    </template>

  </div>

</template>

<script>

import {box} from 'tweetnacl';
import {
  decodeBase64,
  encodeBase64,
  encodeUTF8
} from 'tweetnacl-util';
import PrettyOutput from "@/components/sensitive/PrettyOutput";

export default {
  name: "FileDecryptionDialog",
  components: {PrettyOutput},
  mounted() {
    if (this.is_decryptable_in_browser) {
      this.retrieve_report(this.report_id);
    }
  },

  data() {
    return {
      encrypted_data: null,
      decrypted_data: null,

      // todo: give advice to download instead(!)
      private_key: null,
      loading: false,

      // interaction:
      show_decription_error: false
    }
  },

  props: {
    report_id: {type: Number, required: true},
    wsm_public_key: {type: String, required: true},
    extension: {type: String, required: true},
  },

  computed: {
    is_decryptable_in_browser(){
      let is_decryptable_in_browser = ['json', 'txt', 'nuclei'].includes(this.extension);
      console.log('is_decryptable_in_browser', is_decryptable_in_browser);
      return is_decryptable_in_browser;
    }

  },

  methods: {
    retrieve_report: function (report_id) {

      if (!report_id)
        return;

      this.server_response = {};
      this.loading = true;
      this.$http.get(`/sensitive_reports/report/${report_id}/`).then(data => {
        this.encrypted_data = data.data.report_data;
        this.loading = false;
      })
    },

    get_nonce(){
        // required in the tweetnacl example tool, so you can decrypt stuff there if you want
        // https://tweetnacl.js.org/#/box
        const messageWithNonceAsUint8Array = decodeBase64(this.encrypted_data);
        return encodeBase64(messageWithNonceAsUint8Array.slice(0, box.nonceLength));
    },

    get_message(){
        // required in the tweetnacl example tool, so you can decrypt stuff there if you want
        // https://tweetnacl.js.org/#/box
        const messageWithNonceAsUint8Array = decodeBase64(this.encrypted_data);
        return encodeBase64(messageWithNonceAsUint8Array.slice(box.nonceLength, this.encrypted_data.length));
    },

    decrypt_report: function () {
      // https://github.com/dchest/tweetnacl-js/wiki/Examples
      this.show_decription_error = false;
      try {
        const messageWithNonceAsUint8Array = decodeBase64(this.encrypted_data);
        const nonce = messageWithNonceAsUint8Array.slice(0, box.nonceLength);
        const message = messageWithNonceAsUint8Array.slice(box.nonceLength, this.encrypted_data.length);
        let nacl_public_key = decodeBase64(this.wsm_public_key)
        let nacl_private_key = decodeBase64(this.private_key)

        // prevent people forgetting to wipe their private key
        this.private_key = null;

        this.decrypted_data = encodeUTF8(box.open(message, nonce, nacl_public_key, nacl_private_key))
      } catch (e) {
        this.show_decription_error = true;
      }
    }

  }
}
</script>

<style scoped>

.decrypted {
  min-height: 10em;

}

</style>

<i18n>
{
  "en": {
    "decrypted_data": "Decrypted data",
    "could_not_decrypt_data": "Could not decrypt report. Did you enter your private key correctly?",
    "decryption_in_browser_warning": "Decryption in the browser is a nice-to-have and comes with risks of leaking sensitive data. It's possible to download the results and decrypt them locally using a decryption tool that supports xsalsa20poly1305 (NaCl)."
  },
  "nl": {
    "decrypted_data": "Ontsleutelde gegevens",
    "could_not_decrypt_data": "Ontsleutelen mislukt. Is de juiste sleutel ingegeven?",
    "decryption_in_browser_warning": "Ontsleutelen in de browser is een 'nice-to-have' maar komt met risico's op het uitlekken van gegevens. Het is aan te raden het rapport te downloaden en het bestand lokaal te ontsleutelen met een tool dat xsalsa20poly1305 (NaCl) implementeert."
  }
}


</i18n>
