// Note that there is a little hate going on with multiple vue instances.
// See here: https://github.com/LinusBorg/portal-vue/issues/201#issuecomment-484452281
// This explains why there are some extra definitions in the bundler thingies.
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import VueRouter from 'vue-router'

import PortalVue from 'portal-vue'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import {parseISO, formatDistanceToNow, format} from 'date-fns'
import {enGB, nl} from 'date-fns/locale'

Vue.use(PortalVue);
Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

import vSelect from "vue-select";
Vue.component("v-select", vSelect);


import autorefresh from './components/autorefresh'
import loading from './components/loading'
import server_response from './components/server-response'
Vue.component('autorefresh', autorefresh)
Vue.component('loading', loading)
Vue.component('server-response', server_response)

import App from './App'

import './assets/css/styles.scss';

Vue.config.productionTip = false;

const store = new Vuex.Store({
  state: {
    plus_endpoint: process.env.VUE_APP_DJANGO_PATH,

    // login states
    user: {
      is_authenticated: false,
      is_superuser: false,
      is_adminr: false,
      account_public_key: '',
      signed_full_scope_waiver: false,
      account_name: '',
    },

    network_issue: false,
    network_issue_at: null,

    fullscopetest: {
      url: '',
    }

  },

  mutations: {
    set_user(state, value) {
      state.user = value;
    },
    set_network_issue(state, value) {
      state.network_issue = value;
    },
    set_locale(state, value) {
      state.locale = value;
    }
  },

  plugins: [createPersistedState()],
});



import i18n from './i18n';

Vue.mixin(
  {
    methods: {
      humanize_datetime: function (date) {
        return format(parseISO(date), 'PPPPpppp', {locale: this.dateLocales[this.$i18n.locale]});
      },
      humanize_date: function (date) {
        return format(parseISO(date), 'PPPP', {locale: this.dateLocales[this.$i18n.locale]});
      },
      humanize_relative_date: function (date) {
        return formatDistanceToNow(parseISO(date), {addSuffix: true, locale: this.dateLocales[this.$i18n.locale]})
      },
    },
    computed: {
      dateLocales: function () {
        return {nl: nl, en: enGB}
      }
    },
  }
);

import http from './httpclient';

http.interceptors.response.use((response) => {
  store.commit("set_network_issue", false);
  return response
}, (error) => {

  // todo: also on 404.
  if (error.response === undefined) {
    store.commit("set_network_issue", true);
    return Promise.reject(error.response);
  }

  // all other rest states.
  return error
});

Vue.prototype.$http = http;

import router from './router';

// https://www.digitalocean.com/community/tutorials/vuejs-vue-router-modify-head
router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  }

  let unauthenticated_pages = ['login', 'request', 'signup', 'home', 'apidocs', 'docs', 'foundation']
  // https://router.vuejs.org/guide/advanced/navigation-guards.html#global-before-guards
  if (!unauthenticated_pages.includes(to.name) && !store.state.user.is_authenticated) next({name: 'login'})
  else next()
});

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app')

