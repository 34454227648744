<template>
    <b-card :header-bg-variant="severity" header-text-variant="light" :footer="finding.info.tags.join(', ')">
      <template #header>
        <h4 class="mb-0">{{ finding.info.name }}</h4>
      </template>

      <template v-if="finding.info.description">
        {{finding.info.description}}<br><br>
      </template>

      <h5>Discovery information</h5>
      <template v-if="finding.type">Type: {{finding.type}}<br></template>
      <template v-if="finding['matcher-name']">Matcher: {{finding['matcher-name']}}<br></template>
      <template v-if='finding["matched-at"]'>Where: {{finding["matched-at"]}}<br></template>
      <template v-if='finding["extracted-results"]'>Finding: {{finding["extracted-results"]}}<br></template><br>

      <template v-if="finding.info.reference">
        <h5>References</h5>
        <template v-for="url in finding.info.reference">
          <a :href="url" target="_blank" rel="nofollow" :key="url" class="mr-2">{{ hostname(url) }}</a>
        </template><br>
      </template>

      <template v-if="finding.info.classification">
        <b-badge v-if='finding.info.classification["cve-id"]'>{{finding.info.classification["cve-id"].join(", ")}}</b-badge>
        <b-badge v-if='finding.info.classification["cwe-id"]'>{{finding.info.classification["cwe-id"].join(", ")}}</b-badge>
        <b-badge v-if='finding.info.classification["cvss-metrics"]'>{{finding.info.classification["cvss-metrics"]}}</b-badge>
        <br><br>
      </template>

      <h5>Complete result data</h5>
      <b-textarea :value="finding" />

    </b-card>

</template>

<script>
export default {
  name: "NucleiFinding",
  props: {
    finding: {type: Object}
  },
  methods: {
    hostname(link) {
      // All other solutionos such like with Url or createelement have design side effects, wider columns etc in ffx
      return link.match(/(\w+)\.\w{2,}(\/|\?|$)/)[1];
    }

  },
  computed: {
    severity() {
      if (this.finding.info.severity === "critical") return "danger";
      if (this.finding.info.severity === "high") return "danger";
      if (this.finding.info.severity === "medium") return "warning";
      if (this.finding.info.severity === "low") return "secondary";
      if (this.finding.info.severity === "info") return "secondary";
      if (this.finding.info.severity === "unknown") return "secondary";
      return "secondary"
    }
  }
}
</script>

<style scoped>

</style>
