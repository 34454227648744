<template>
  <div>
    <server-response :response="server_response" v-if="server_response" :message="$t('' + server_response.message)"/>
    <loading :loading="loading"></loading>

    <template>
        <b-form-group
          id="input-group-username"
          label="Username (min 10 chars):"
          label-for="input-username"
        >
          <b-form-input
            id="input-username"
            v-model="form.desired_username"
            type="text"
            placeholder="Username"
            required
          ></b-form-input>
        </b-form-group>

        <template v-if="!loading">
          <b-button class="defaultbutton modal-default-button" variant="success" @click="add()">
            {{ $t("ok") }}
          </b-button>
        </template>
        <template v-else>
          <b-button disabled="disabled" variant="success" class="defaultbutton modal-default-button">
            <b-icon icon="arrow-clockwise" animation="spin"></b-icon>
            {{ $t("loading") }}
          </b-button>
        </template>
      </template>

    <template v-if="new_account.password">
        <h3>New account added</h3>
        <p>
          <b>Below credentials will not be shown again. Store them in a safe place.
          </b>
        </p>

        <b-table-simple style="border: 2px dashed red">
          <tr>
            <th>Username</th>
            <td>{{ new_account.username }}</td>
          </tr>
          <tr>
            <th>Password</th>
            <td>{{ new_account.password }}</td>
          </tr>
        </b-table-simple>

      <b-button variant="secondary" @click="new_account = {}">Remove data</b-button>
    </template>

  </div>
</template>

<script>

export default {
  data: function () {
    return {
      loading: false,
      server_response: {},
      form: {
        desired_username: '',
      },
      new_account: {},

    }
  },
  methods: {
    add: function () {
      this.loading = true;
      this.server_response = {};
      this.$http.post('/account/add_user/', this.form).then(data => {
        this.server_response = data.data;
        if (data.data.success) {
          this.new_account = data.data.data;
          this.$emit('new_account', this.new_account);
        } else {
          this.new_account = {}
        }
        this.loading = false;
      });
    },
  },
}
</script>
<i18n>
{
  "en": {
    "username_too_short": "Username must be at least 10 characters long.",
    "username_already_exists": "Username already exists.",
    "account_has_too_many_users": "Account has too many users.",
    "user_created": "User created"
  },
  "nl": {
    "username_too_short": "Gebruikersnaam moet ten minste 10 karakters lang zijn.",
    "username_already_exists": "Deze gebruikersnaam is al in gebruik.",
    "account_has_too_many_users": "Dit account heeft teveel gebruikers.",
    "user_created": "Gebruiker aangemaakt"
  }

}

</i18n>
