<template>
  <div>


    <b-jumbotron bg-variant="success" text-variant="white">
      <template #header>
        <span style="font-size: 0.6em;">Basisbeveiliging+ / Deelnemerschap</span>
      </template>

      <template #lead>
        Basisbeveiliging+ is een toevoeging op de gratis publieke monitoring dienst op basisbeveiliging.nl.
      </template>

      <hr class="my-4">

      <p>In control op veiligheid en privacy door ⏰ alerts, 🕵️ herscans, 🪲 volledige tests en 💬 verklaringen.</p>

      <p>
        Los problemen op voordat ze op de kaart staan en blijf
        <b-icon icon="check-circle-fill" variant="light" aria-hidden="true"></b-icon>
        &nbsp;<b>groen</b>.
      </p>

      <b-button v-if="user.is_authenticated === false" to="request" variant="light">
        Interesse? Word deelnemer van de Internet Cleanup Foundation! Vraag vrijblijvend een offerte aan voor deelnemerschap.
      </b-button>

    </b-jumbotron>

    <b-container class="bv-example-row">

      <b-row class="mb-4">
        <b-col>
          Met het deelnemerschap van de Internet Cleanup Foundation steun je een veilige online wereld, onderzoek naar security en privacy,
          verdere ontwikkeling aan basisbeveiliging.nl en meer. Je krijgt er een community bij van gelijkgestemden: wij gaan samen voor een veiliger online wereld.<br>
          Lees <a href="https://internetcleanup.foundation/deelnemerschap/">hier</a> meer over het deelnemerschap van de Internet Cleanup Foundation.
        </b-col>
      </b-row>

      <b-row class="mb-4">
        <b-col>
          Basisbeveiliging+ is alleen beschikbaar voor deelnemers. Dit zit er allemaal in:
        </b-col>
      </b-row>

      <b-row class="mb-4">
        <b-col md="6">
          <b-img-lazy class="mt-2 mb-4" src="static_frontend/portfolio.png" thumbnail fluid rounded
                      alt="Screenshot van het portfolio"></b-img-lazy>
        </b-col>
        <b-col>
          <h2>Portfolio</h2>
          <p>Alle domeinen in het portfolio in een oogopslag. Het portfolio wordt automatisch aangvuld met nieuwe
            domeinen, maar zelf aanvullen kan ook. Vraag meteen een herscan aan en bekijk de historie tot jaren
            terug.</p>

          <ul style="list-style: none; margin-left: -20px;">
            <li>
              <b-icon icon="check-circle-fill" variant="success" aria-hidden="true"></b-icon>
              Overzicht domeinportfolio
            </li>
            <li>
              <b-icon icon="check-circle-fill" variant="success" aria-hidden="true"></b-icon>
              Beheer meerdere organisaties met één login
            </li>
            <li>
              <b-icon icon="check-circle-fill" variant="success" aria-hidden="true"></b-icon>
              Toevoegen van domeinen op de basisbeveiliging kaart
            </li>
            <li>
              <b-icon icon="check-circle-fill" variant="success" aria-hidden="true"></b-icon>
              Nieuwe subdomeinen worden automatisch toegevoegd
            </li>
            <li>
              <b-icon icon="check-circle-fill" variant="success" aria-hidden="true"></b-icon>
              Inzage wijzigingshistorie per domein
            </li>
          </ul>
        </b-col>
      </b-row>


      <b-row class="mb-4">
        <b-col md="6">
          <b-img-lazy class="mt-2 mb-4" src="static_frontend/findings.png" thumbnail fluid rounded
                      alt="Screenshot van de bevindingen"></b-img-lazy>
        </b-col>
        <b-col>
          <h2>Bevindingen</h2>
          <p>Bekijk alle actuele bevindingen in 1x en bepaal wat ermee te doen. Voer een fix uit en plan een herscan in.
            Of, wanneer de afwijking nodig is "by design", voeg dan een verklaring toe.</p>
          <ul style="list-style: none; margin-left: -20px;">
            <li>
              <b-icon icon="check-circle-fill" variant="success" aria-hidden="true"></b-icon>
              Actuele inzage in openstaande bevindingen
            </li>
            <li>
              <b-icon icon="check-circle-fill" variant="success" aria-hidden="true"></b-icon>
              Direct aanvragen van nieuwe scans
            </li>
            <li>
              <b-icon icon="check-circle-fill" variant="success" aria-hidden="true"></b-icon>
              Verklaringen aan bevindingen toevoegen
            </li>
            <li>
              <b-icon icon="check-circle-fill" variant="success" aria-hidden="true"></b-icon>
              Advies voor het geven van betrouwbare verklaringen
            </li>
          </ul>
        </b-col>

      </b-row>

      <b-row class="mb-4">
        <b-col md="6">
          <b-img-lazy class="mt-2 mb-4" src="static_frontend/alerts.png" thumbnail fluid rounded
                      alt="Screenshot van e-mail meldingen"></b-img-lazy>
        </b-col>
        <b-col>
          <h2>Meldingen bij terugval</h2>
          <p>Stel diverse e-mail adressen in voor het ontvangen van meldingen over nieuwe bevindingen. Kies tussen
            dagelijkse of directe meldingen. Zo kan er direct gehandeld worden bij terugval of bij nog niet juist
            ingestelde domeinen.</p>

          <ul style="list-style: none; margin-left: -20px;">
            <li>
              <b-icon icon="check-circle-fill" variant="success" aria-hidden="true"></b-icon>
              Blijf in control op het portfolio door meldingen.
            </li>
            <li>
              <b-icon icon="check-circle-fill" variant="success" aria-hidden="true"></b-icon>
              Directe en/of dagelijkse meldingen bij regressie.
            </li>
          </ul>
        </b-col>
      </b-row>

      <b-row class="mb-4">
        <b-col md="6">
          <b-img-lazy class="mt-2 mb-4" src="static_frontend/comply_or_explain.png" thumbnail fluid rounded
                      alt="Screenshot van comply or explain"></b-img-lazy>
        </b-col>
        <b-col>
          <h2>Pas toe of leg uit / comply or explain</h2>
          <p>Voeg verklaringen toe waarom er wordt afgeweken van gebruikelijke veiligheidsstandaarden.</p>

          <ul style="list-style: none; margin-left: -20px;">
            <li>
              <b-icon icon="check-circle-fill" variant="success" aria-hidden="true"></b-icon>
              Transparant: Verklaringen zijn publiek en door iedereen in te zien.
            </li>
            <li>
              <b-icon icon="check-circle-fill" variant="success" aria-hidden="true"></b-icon>
              Verklaar unieke situaties die niet automatisch kunnen worden gedetecteerd.
            </li>
            <li>
              <b-icon icon="check-circle-fill" variant="success" aria-hidden="true"></b-icon>
              Originele meting blijft inzichtelijk in het rapport, maar wordt doorgestreept.
            </li>
            <li>
              <b-icon icon="check-circle-fill" variant="success" aria-hidden="true"></b-icon>
              Altijd en voor iedereen: Automatische verklaringen door <a href="https://internetcleanup.foundation/project-basisbeveiliging/meetbeleid/uitzonderingen-op-het-meetbeleid/" target="_blank">standaard uitzonderingen</a>.
            </li>
            <li>
              <b-icon icon="check-circle-fill" variant="success" aria-hidden="true"></b-icon>
              Ook bruikbaar zonder basisbeveiliging+ via de website: voor bijzondere gevallen en/of meetfouten.
            </li>
          </ul>
        </b-col>
      </b-row>

      <b-row class="mb-4">
        <b-col md="6">
          <b-img-lazy class="mt-2 mb-4" src="static_frontend/fullscopetest.png" thumbnail fluid rounded
                      alt="Screenshot van dialoog om full scope tests te starten"></b-img-lazy>
        </b-col>
        <b-col>
          <h2>Full Scope Tests</h2>
          <p>Voer volledige security tests uit met bekende security tools. Deze worden alleen op
          aanvraag vanuit je portfolio ingepland. Onbeperkt volgens fair use policy: dus meet zoveel je
          wil.</p>

          <ul style="list-style: none; margin-left: -20px;">
            <li>
              <b-icon icon="check-circle-fill" variant="success" aria-hidden="true"></b-icon>
              Versleuteld: het rapport is alleen te lezen met jouw sleutel.
            </li>
            <li>
              <b-icon icon="check-circle-fill" variant="success" aria-hidden="true"></b-icon>
              Vertrouwelijk: zelfs wij kunnen je rapport niet lezen!
            </li>
            <li>
              <b-icon icon="check-circle-fill" variant="success" aria-hidden="true"></b-icon>
              Op dit moment ondersteund: Nuclei, met meer dan 5000 tests.
            </li>
            <li>
              <b-icon icon="check-circle-fill" variant="success" aria-hidden="true"></b-icon>
              Sanity check op je full scope test voor aanvang van iedere test.
            </li>
            <li>
              <b-icon icon="check-circle-fill" variant="success" aria-hidden="true"></b-icon>
              Meer deelnemers betekent: meer full scope tests.
            </li>
             <li>
              <b-icon icon="check-circle-fill" variant="success" aria-hidden="true"></b-icon>
              Alleen mogelijk na getekende vrijwaring.
            </li>
          </ul>
        </b-col>
      </b-row>

      <b-row class="mb-4">
        <b-col md="6">
          <b-table-simple small fixed >
            <b-thead>
            <b-tr>
              <b-th>Veiligheidsstandaard</b-th>
              <b-th>Technieken</b-th>
            </b-tr>
            </b-thead>
            <b-tbody>
            <b-tr variant="danger">
              <b-td>Integriteit domeinnaam</b-td>
              <b-td>DNSSEC</b-td>
            </b-tr>
            <b-tr variant="danger">
              <td>Kwaliteit van versleuteling</td>
              <td>HTTP / TLS</td>
            </b-tr>
            <b-tr variant="danger">
              <b-td>Vertrouwen in het versleuteling</b-td>
              <b-td>HTTP / TLS</b-td>
            </b-tr>
            <b-tr variant="danger">
              <b-td>Toepassen van versleuteling</b-td>
              <b-td>HTTP / FTP / E-Mail</b-td>
            </b-tr>
            <b-tr variant="danger">
              <b-td>Afschermen voor inbrekers</b-td>
              <b-td>Login portals, versienummers</b-td>
            </b-tr>
            <b-tr variant="danger">
              <b-td>Privacy advertenties</b-td>
              <b-td>Plaating volgcookies zonder toestemming</b-td>
            </b-tr>
            <b-tr variant="warning">
              <b-td>Veilig en vertrouwd e-mailen</b-td>
              <b-td>SPF / DKIM / DMARC</b-td>
            </b-tr>
            <b-tr variant="warning">
              <b-td>Veilig en vertrouwd browsen</b-td>
              <b-td>HSTS / X-Frame-Options en meer</b-td>
            </b-tr>
            <b-tr variant="warning">
              <b-td>Veilige organisatie</b-td>
              <b-td>Security.txt, WHOIS gegevens</b-td>
            </b-tr>
            <b-tr variant="warning">
              <b-td>Privacy op de website</b-td>
              <b-td>Fysieke locatie van servers en inhoud website, trackers op de site</b-td>
            </b-tr>
            </b-tbody></b-table-simple>
        </b-col>

        <b-col>
          <h2>Metingen en monitoring</h2>
          <p>Basisbeveiliging meet een keur aan basisinstellingen die noodzakelijk zijn voor veiligheid en privacy.
            Dit wordt doorlopend gemeten en gerapporteerd. Waaronder:</p>
          <ul style="list-style: none; margin-left: -20px;">
            <li>
              <b-icon icon="check-circle-fill" variant="success" aria-hidden="true"></b-icon>
              Domeinnaam: DNSSEC
            </li>
            <li>
              <b-icon icon="check-circle-fill" variant="success" aria-hidden="true"></b-icon>
              Versleuteling: HTTP, E-Mail, FTP
            </li>
            <li>
              <b-icon icon="check-circle-fill" variant="success" aria-hidden="true"></b-icon>
              E-Mail: SPF, DKIM, DMARC
            </li>
            <li>
              <b-icon icon="check-circle-fill" variant="success" aria-hidden="true"></b-icon>
              Instellingen voor gebruikers: HTTP
            </li>
            <li>
              <b-icon icon="check-circle-fill" variant="success" aria-hidden="true"></b-icon>
              En meer: Privacy, Hosting / Site Geolocatie, Login portals, RPKI, Security.txt, WHOIS, Volgcookies
            </li>
          </ul>
        </b-col>
      </b-row>

    </b-container>

    <b-container class="mt-4 mb-4 p-4" v-if="user.is_authenticated === false">
      <template>
        <p style="text-align: center">
          <b-button to="request" variant="success">
            Interesse? Word deelnemer van de Internet Cleanup Foundation! Vraag vrijblijvend een offerte aan voor deelnemerschap.
          </b-button>
        </p>
      </template>
    </b-container>

    <b-container>
      <b-row>
        <b-col>
          <h3>Wat is Basisbeveiliging+?</h3>
          <p>Met Basisbeveiliging+ wordt het makkelijk om de basis van security en privacy te beheren op grote
            hoeveelheden domeinen.
            Ontvang automatische alerts, voer herstel uit en herscan voordat een nieuw rapport is gemaakt. Het is zelfs
            mogelijk om verklaringen toe te voegen
            wanneer het noodzakelijk is om af te wijken van de standaarden.</p>

          <h4>Ook zonder Basisbeveiliging+ bent u up to date!</h4>
          <p>
            Wij, de Internet Cleanup Foundation, streven naar een veilig en privacyvriendelijk internet.
            Vanuit die doelstelling houden we de kaart altijd actueel. Daardoor kunt u iedere security-aanbieder
            gebruiken om op groen te blijven staan. Maar basisbeveiliging+ maakt het wel makkelijker.
          </p>
        </b-col>
        <b-col>
          <h3>Wat kan je met Basisbeveiliging Plus?</h3>
          <p>Dit is een overzicht van alles dat onder basisbeveiliging Plus te vinden is.</p>
          <ul style="list-style: none; margin-left: -20px;">
            <li>
              <b-icon icon="check-circle-fill" variant="success" aria-hidden="true"></b-icon>
              Directe en/of dagelijkse meldingen bij gemeten verslechteringen
            </li>
            <li>
              <b-icon icon="check-circle-fill" variant="success" aria-hidden="true"></b-icon>
              Direct inplannen van nieuwe scans, bijvoorbeeld nadat een probleem opgelost is
            </li>
            <li>
              <b-icon icon="check-circle-fill" variant="success" aria-hidden="true"></b-icon>
              Toevoegen van domeinen op de basisbeveiliging kaart
            </li>
            <li>
              <b-icon icon="check-circle-fill" variant="success" aria-hidden="true"></b-icon>
              Verklaringen aan bevindingen toevoegen als met opzet wordt afgeweken van een standaard
            </li>
            <li>
              <b-icon icon="check-circle-fill" variant="success" aria-hidden="true"></b-icon>
              Advies voor het geven van betrouwbare verklaringen
            </li>
            <li>
              <b-icon icon="check-circle-fill" variant="success" aria-hidden="true"></b-icon>
              Bekijken domeinportfolio en wijzigingshistorie per domein
            </li>
            <li>
              <b-icon icon="check-circle-fill" variant="success" aria-hidden="true"></b-icon>
              Automatisch nieuwe domeinen toegevoegd aan het portfolio.
            </li>
          </ul>

        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <h3>Wat kan je van ons verwachten</h3>
          <ul style="list-style: none; margin-left: -20px;">
            <li>
              <b-icon icon="check-circle-fill" variant="success" aria-hidden="true"></b-icon>
              Best effort support (op werkdagen), gebruikelijk binnen 48 uur: <a
              href="mailto:info@internetcleanup.foundation">info@internetcleanup.foundation</a></li>
            <li>
              <b-icon icon="check-circle-fill" variant="success" aria-hidden="true"></b-icon>
              Ontwikkeling en bugfixes
            </li>
            <li>
              <b-icon icon="check-circle-fill" variant="success" aria-hidden="true"></b-icon>
              Ondersteuning met scans
            </li>
            <li>
              <b-icon icon="check-circle-fill" variant="success" aria-hidden="true"></b-icon>
              Dagelijkse rapportage update
            </li>
          </ul>


        </b-col>
        <b-col>
          <p>Meer nodig? Stuur een feature request naar: <a href="mailto:info@internetcleanup.foundation">info@internetcleanup.foundation</a>
          </p>
        </b-col>

      </b-row>

    </b-container>

    <b-container class="mt-4 mb-4 p-4" v-if="user.is_authenticated === false">
      <template>
        <p style="text-align: center">
          <b-button to="request" variant="success">
            Interesse? Word deelnemer van de Internet Cleanup Foundation! Vraag vrijblijvend een offerte aan voor deelnemerschap.
          </b-button>
        </p>
      </template>
    </b-container>


  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: "Home.vue",
  computed: mapState(['user']),
}
</script>

<style scoped>
.text-large {
  font-size: 1.2rem;
}
</style>
<i18n>
{
  "en": {
    "title": "Basisbeveiliging+",
    "introduction": ""
  },
  "nl": {
    "title": "Basisbeveiliging+",
    "introduction": " "
  }
}
</i18n>
