import Login from "@/components/login/Login";
import Signup from "@/components/signup/Signup";
import Home from "@/components/home/Home";
import Portfolio from "@/components/portfolio/portfolio";
import ComplyOrExplain from "@/components/issues/issues";
import Alerts from "@/components/alerts/alerts";
import SwitchAccount from "@/components/admin/SwitchAccount";
import ListAccounts from "@/components/admin/ListAccounts";
import VueRouter from "vue-router";

import i18n from './i18n';
import SensitiveReportsPage from "@/components/sensitive/SensitiveReportsPage";
import SetupPublicKeyPage from "@/components/sensitive/SetupPublicKeyPage";
import ApiDocsPage from "@/components/docs/ApiDocsPage";
import FullScopeScanPage from "@/components/fullscopetests/FullScopeScanPage";
import DevPage from "@/components/DevPage";
import AccountPage from "@/components/account/AccountPage";

const routes = [
  {
    path: '/login',
    component: Login,
    name: "login",
    meta: {title: `${i18n.t('title')} - Login`}
  },
  {
    path: '/request',
    component: Signup,
    name: 'request',
    meta: {title: `${i18n.t('title')} - Offerte`}
  },
  {
    path: '/home',
    component: Home,
    name: 'home',
    meta: {title: `${i18n.t('title')} - Home`},
    alias: '/'
  },
  {
    path: '/portfolio',
    component: Portfolio,
    name: 'portfolio',
    meta: {title: `${i18n.t('title')} - Portfolio`},
  },
  {
    path: '/findings',
    component: ComplyOrExplain,
    name: 'findings',
    meta: {title: `${i18n.t('title')} - Findings`},
  },
  {
    path: '/findings/:url',
    component: ComplyOrExplain,
    name: 'findings with url',
    meta: {title: `${i18n.t('title')} - Findings`},
  },
  {
    path: '/alerts',
    component: Alerts,
    name: 'alerts',
    meta: {title: `${i18n.t('title')} - Alerts`},
  },
  {
    path: '/switch-account',
    component: SwitchAccount,
    meta: {title: `${i18n.t('title')} - Switch Account`}
  },
  {
    path: '/add-account',
    component: ListAccounts,
    meta: {title: `${i18n.t('title')} - Add Account`}
  },
  {
    path: '/sensitivereports',
    component: SensitiveReportsPage,
    meta: {title: `${i18n.t('title')} - Sensitive Reports`}
  },
  {
    path: '/publickey',
    component: SetupPublicKeyPage,
    meta: {title: `${i18n.t('title')} - Set up public key`}
  },
  {
    path: '/accounts-users',
    component: AccountPage,
    meta: {title: `${i18n.t('title')} - Manage users`}
  },
  {
    path: '/apidocs',
    name: 'apidocs',
    component: ApiDocsPage,
    meta: {title: `${i18n.t('title')} - DIVD API Documentation`}
  },
  {
    path: '/fullscope',
    name: 'fullscope',
    component: FullScopeScanPage,
    meta: {title: `${i18n.t('title')} - Full Scope Scans`}
  },
  {
    path: '/dev',
    name: 'dev',
    component: DevPage,
    meta: {title: `${i18n.t('title')} - Dev Page`}
  },
];


const router = new VueRouter({
  routes, // short for `routes: routes`
  props: true,
  // https://reactgo.com/scroll-to-anchor-tags-vue-router/
  // does not work, as nested anchors is not a thing (and not reliable). So do this in component.
  scrollBehavior: function (to) {
    if (to.hash) {
      return {selector: to.hash}
    }
  },
});

export default router
