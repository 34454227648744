<template>
  <div>
    <h1>Deelnemer worden van de Internet Cleanup Foundation <br><i>Inclusief toegang tot Basisbeveiliging+</i></h1>
    <p>Met de gegevens in dit formulier wordt direct een offerte gemaakt voor deelnemerschap aan de Internet Cleanup
      Foundation. Deze is te downloaden na drie stappen.</p>
    <p><i>De ingevulde gegevens worden alleen gebruikt voor de Internet Cleanup Foundation en worden bij geen
      interesse verwijderd.</i></p>

    <template v-if="signup_success">


      <b-alert show variant="success" v-if="form.product_name === 'icf_plus_participant'">
        <h2>Download de offerte</h2>
        <p>De aanvraag is gelukt, klik hieronder om de offerte te downloaden als PDF.</p>

        <b-button size="lg" variant="success" :href="`/api/offer_pdf/${this.signup_data.download_id}/`">
          <b-icon icon="file-earmark-check"/>
          Download de offerte
        </b-button>
        &nbsp;
        <b-button size="lg" variant="danger" :href="`/api/offer_pdf/${this.signup_data.download_id}/`">
          <b-icon icon="file-earmark-check"/>
          Download de offerte
        </b-button>
        &nbsp;
        <b-button size="lg" variant="warning" :href="`/api/offer_pdf/${this.signup_data.download_id}/`">
          <b-icon icon="file-earmark-check"/>
          Download de offerte
        </b-button>

        <br><br>
        <p>Bij deze offerte horen de volgende voorwaarden: Algemene voorwaarden van <a
          href="/static_frontend/av/NLdigital-Voorwaarden.pdf">NLdigital</a> en de <a
          href="/static_frontend/av/Aanvulling AV Basisbeveiliging+ Juni 2021.pdf">aanvullende voorwaarden
          Basisbeveiliging+</a>.</p>


        <p>Stuur de offerte na ondertekening op naar: <a
          href="mailto:info@internetcleanup.foundation?subject=basisbeveiliging+%20offerte">info@internetcleanup.foundation</a>.</p>

        <p><i>De ingevulde gegevens worden alleen gebruikt voor deze offerte en worden bij geen
          interesse verwijderd.</i></p>
        <b-button to="home" variant="success">Terug naar de homepagina</b-button>
      </b-alert>


      <b-alert show variant="success" v-if="form.product_name === 'icf_premium_support'">

        <h2>Aanvraag succesvol verstuurd</h2>
        <p>De aanvraag voor premium support is succesvol vestuurd. Wij nemen binnen twee weken contact op met eventuele
          vragen of met een offerte.</p>

        <p>Mochten er extra stukken nodig zijn voor uw inkoopproces, dan kunt u deze na uw aanvraag mailen naar <a href="mailto:info@internetcleanup.foundation?subject=premium%20support%20documenten">info@internetcleanup.founation</a>. Contactgegevens en nummers van Stichting Internet Cleanup Foundation staan op de <a href="https://internetcleanup.foundation/about-us/" target="_blank" rel="nofollow">over ons pagina</a>.</p>

        <p>Bedankt voor uw aanvraag!</p>

        <b-button to="home" variant="success">Terug naar de homepagina</b-button>

      </b-alert>

      <b-button @click="reset">Opnieuw het formulier invullen</b-button>
    </template>

    <b-form v-if="!signup_success" @submit="onSubmit">
      <b-tabs v-model="tabIndex" card content-class="mt-0" pills vertical>

        <b-tab :active="tabIndex === 0" title="1: Aanbod">
          <b-table-simple responsive>
            <b-thead head-variant="success">
              <b-tr variant="success">
                <b-th></b-th>
                <b-th class="text-center">Deelnemerschap ICF</b-th>
                <b-th class="text-center">Premium Support</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr>
                <b-td>Toegang tot basisbeveiliging Plus</b-td>
                <b-td class="text-center">
                  <b-icon aria-hidden="true" icon="check-circle-fill" variant="success"></b-icon>
                </b-td>
                <b-td class="text-center">
                  <b-icon aria-hidden="true" icon="check-circle-fill" variant="success"></b-icon>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td style="width: 38%">Aantal domeinen in portfolio</b-td>
                <b-td style="width: 31%" class="text-center">{{ this.standard_domains }}(sub)domeinen</b-td>
                <b-td style="width: 31%" class="text-center">Nader te bepalen</b-td>
              </b-tr>
              <b-tr>
                <b-td>Instant en dagelijkse e-mail waarschuwingen bij verslechtering</b-td>
                <b-td class="text-center">
                  <b-icon aria-hidden="true" icon="check-circle-fill" variant="success"></b-icon>
                </b-td>
                <b-td class="text-center">
                  <b-icon aria-hidden="true" icon="check-circle-fill" variant="success"></b-icon>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>Inplannen extra scans</b-td>
                <b-td class="text-center">
                  <b-icon aria-hidden="true" icon="check-circle-fill" variant="success"></b-icon>
                </b-td>
                <b-td class="text-center">
                  <b-icon aria-hidden="true" icon="check-circle-fill" variant="success"></b-icon>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>Comply or Explain</b-td>
                <b-td class="text-center">
                  <b-icon aria-hidden="true" icon="check-circle-fill" variant="success"></b-icon>
                </b-td>
                <b-td class="text-center">
                  <b-icon aria-hidden="true" icon="check-circle-fill" variant="success"></b-icon>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>Toevoegen van domeinen</b-td>
                <b-td class="text-center">
                  <b-icon aria-hidden="true" icon="check-circle-fill" variant="success"></b-icon>
                </b-td>
                <b-td class="text-center">
                  <b-icon aria-hidden="true" icon="check-circle-fill" variant="success"></b-icon>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>Inzicht in meethistorie</b-td>
                <b-td class="text-center">
                  <b-icon aria-hidden="true" icon="check-circle-fill" variant="success"></b-icon>
                </b-td>
                <b-td class="text-center">
                  <b-icon aria-hidden="true" icon="check-circle-fill" variant="success"></b-icon>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>Discord community</b-td>
                <b-td class="text-center">
                  <b-icon aria-hidden="true" icon="check-circle-fill" variant="success"></b-icon>
                </b-td>
                <b-td class="text-center">
                  <b-icon aria-hidden="true" icon="check-circle-fill" variant="success"></b-icon>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>Ondersteuning bedoeld voor grote organisaties</b-td>
                <b-td class="text-center">&dash;</b-td>
                <b-td class="text-center">
                  <b-icon aria-hidden="true" icon="check-circle-fill" variant="success"></b-icon>
                </b-td>
              </b-tr>
            </b-tbody>
            <b-tfoot>
              <b-tr variant="success">
                <b-td>
                </b-td>
                <b-td class="text-center">
                  &euro; <span style="font-size: 1.8em;">{{ price_basic }},-</span> / maand
                </b-td>
                <b-td class="pt-4 text-center">
                  Op offertebasis
                </b-td>
              </b-tr>
              <b-tr variant="success">
                <b-td>
                </b-td>
                <b-td>
                  <b-button block size="lg" variant="success"
                            @click="form.product_name = 'icf_plus_participant'; tabIndex++">Deelnemer worden ➤
                  </b-button>
                </b-td>
                <b-td>
                  <b-button block size="lg" variant="success"
                            @click="form.product_name = 'icf_premium_support';  tabIndex++">Offerte aanvragen ➤
                  </b-button>
                </b-td>
              </b-tr>
            </b-tfoot>
          </b-table-simple>
          <i><small>Genoemde prijzen zijn exclusief BTW. </small></i>
          <i><small>Bij deze offerte horen de volgende voorwaarden: <a
            href="/static_frontend/av/NLdigital-Voorwaarden.pdf">Algemene voorwaarden van NLdigital</a> en de <a
            href="/static_frontend/av/Aanvulling AV Basisbeveiliging+ Juni 2021.pdf">aanvullende voorwaarden
            Basisbeveiliging+</a>.</small></i>
        </b-tab>


        <b-tab :active="tabIndex === 1" title="2: Samenstellen">

          <div v-if="form.product_name === 'undecided'">
            <h2>Kies eerst uit het aanbod</h2>
            <p>Samenstellen is beschikbaar nadat uit het aanbod is gekozen.</p>
            <b-button variant="success" @click="tabIndex--">⇦ Ga terug naar het aanbod</b-button>
          </div>


          <div v-if="form.product_name === 'icf_premium_support'">
            <h2>Premium Support</h2>
            <p>

              Premium support is bedoeld voor grote organisaties die vanuit de Internet Cleanup Foundation
              (projectmatige)
              ondersteuning wensen. Bijvoorbeeld voor communicatie over de metingen, het uitvoeren van bijzondere
              metingen, uitvoeren van metingen op grotere schaal en dergelijke.<br>
              <br>
              De vorm van ondersteuning hangt af van de grootte van de organisatie en van het project. Premium support is daarom
              alleen op offertebasis verkrijgbaar. Na het invullen van dit formulier wordt er contact opgenomen voor
              eventueel verdere vragen of ontvangt u direct een offerte.<br>
              <br>
              Mochten er extra stukken nodig zijn voor uw inkoopproces, dan kunt u deze na uw aanvraag mailen naar <a href="mailto:info@internetcleanup.foundation?subject=premium%20support%20documenten">info@internetcleanup.founation</a>. Contactgegevens en nummers van Stichting Internet Cleanup Foundation staan op de <a href="https://internetcleanup.foundation/about-us/" target="_blank" rel="nofollow">over ons pagina</a>.<br>
              <br>
              Na het invullen wordt er gebruikelijk binnen twee weken gereageerd.
            </p>

            <b-table-simple responsive>
              <b-tr>
                <b-th>Wensen</b-th>
                <b-th>Omschrijving</b-th>
              </b-tr>
              <b-tr>
                <b-td>Doel(en) van het project</b-td>
                <b-td>
                  <b-textarea rows="5" v-model="form.product_2024_premium_support_goals"></b-textarea>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>Omvang/scope van het project</b-td>
                <b-td>
                  <b-textarea rows="5" v-model="form.product_2024_premium_support_scope"></b-textarea>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>Gewenste ondersteuning</b-td>
                <b-td>
                  <b-textarea rows="5" v-model="form.product_2024_premium_support_statement_of_needed_support"></b-textarea>
                </b-td>
              </b-tr>
            </b-table-simple>
          </div>


          <div v-if="form.product_name === 'icf_plus_participant'">
            <b-table-simple responsive>
              <b-thead head-variant="success">
                <b-tr variant="success">
                  <b-th>Abonnementsvorm</b-th>
                  <b-th class="text-center">Plus</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td style="width: 50%">Aantal domeinen in portfolio</b-td>
                  <b-td class="text-center">{{ this.standard_domains }}(sub)domeinen
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>Instant en dagelijkse e-mail waarschuwingen bij verslechtering</b-td>
                  <b-td class="text-center">
                    <b-icon aria-hidden="true" icon="check-circle-fill" variant="success"></b-icon>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>Inplannen extra scans</b-td>
                  <b-td class="text-center">
                    <b-icon aria-hidden="true" icon="check-circle-fill" variant="success"></b-icon>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>Comply or Explain</b-td>
                  <b-td class="text-center">
                    <b-icon aria-hidden="true" icon="check-circle-fill" variant="success"></b-icon>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>Toevoegen van domeinen</b-td>
                  <b-td class="text-center">
                    <b-icon aria-hidden="true" icon="check-circle-fill" variant="success"></b-icon>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>Inzicht in meethistorie</b-td>
                  <b-td class="text-center">
                    <b-icon aria-hidden="true" icon="check-circle-fill" variant="success"></b-icon>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>Discord community</b-td>
                  <b-td class="text-center">
                    <b-icon aria-hidden="true" icon="check-circle-fill" variant="success"></b-icon>
                  </b-td>
                </b-tr>
              </b-tbody>
              <b-tfoot>
                <b-tr variant="success">
                  <b-td>
                  </b-td>
                  <b-td class="text-center">
                    &euro; <span style="font-size: 2em;">{{ price_basic }},-</span> / maand
                  </b-td>
                </b-tr>
              </b-tfoot>
            </b-table-simple>


            <b-table-simple caption-top hover responsive small v-if="form.product_name === 'icf_plus_participant'">
              <b-thead head-variant="">
                <b-tr variant="success">
                  <b-th>Opties</b-th>
                  <b-th></b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td>Gewenste extra hoofd-domeinen<br><small>- Deze kunnen altijd worden
                    toegevoegd</small><br><small>- Domeinen kunnen alleen in eigen beheer zijn</small><br><small>-
                    Subdomeinen worden automatisch gevonden</small><br><small>- Subdomeinen tellen in het totaal</small>
                  </b-td>
                  <b-td>

                    <b-form-textarea
                      id="textarea"
                      v-model="form.domains"
                      rows="5"
                      placeholder="Bijvoorbeeld: gemeente.nl, koepelorganisatie.nl. Subdomeinen worden automatisch toegevoegd en tellen mee in het totaal."
                    ></b-form-textarea>

                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>
                    Eventuele aanvullingen/opmerkingen<br><small>- Voorbeelden: administratief contact, </small>
                  </b-td>
                  <b-td>
                    <b-form-textarea
                      id="textarea"
                      v-model="form.remarks"
                      rows="5"
                      placeholder="Bijvoorbeeld administratief contact, facturatiegegevens, ordernummers, projectcodes, suggesties, wensen e.d."
                    ></b-form-textarea>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td style="width: 50%">Extra Domeinen</b-td>
                  <b-td>
                    <b-form-spinbutton id="product_2021_extra_domains_x_100"
                                       v-model="form.product_2021_extra_domains_x_100" :step="domains_per_bundle"
                                       max="100000"
                                       min="0"></b-form-spinbutton>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>Totaal domeinen*</b-td>
                  <b-td>{{ this.total_domains }}</b-td>
                </b-tr>
              </b-tbody>
              <b-tfoot>
                <b-tr variant="success">
                  <b-td>
                  </b-td>
                  <b-td>
                    {{ price_basic }} + &euro; <span style="font-size: 2em;">{{ extra_domain_costs }},-</span> / maand
                  </b-td>
                </b-tr>
              </b-tfoot>
            </b-table-simple>

          </div>

          <div v-if="form.product_name !== 'undecided'">
            <b-button block size="lg" variant="success" @click="tabIndex++">Ga verder ➤</b-button>

            <i><small>Genoemde prijzen zijn exclusief BTW. </small></i>
            <i><small>Bij deze offerte horen de volgende voorwaarden: <a
              href="/static_frontend/av/NLdigital-Voorwaarden.pdf">Algemene voorwaarden van NLdigital</a> en de <a
              href="/static_frontend/av/Aanvulling AV Basisbeveiliging+ Juni 2021.pdf">aanvullende voorwaarden
              Basisbeveiliging+</a>.</small></i>
          </div>

        </b-tab>
        <b-tab :active="tabIndex === 2" title="3: Gegevens" :disabled="form.product_name === 'undecided'">


          <h4>Contactgegevens</h4>
          <b-form-group id="input-group-organization" label="Uw organisatie:" label-for="input-2">
            <b-form-input
              id="input-organization"
              v-model="form.organization"
              placeholder="Organisatienaam / bedrijfsnaam"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-contact" label="Contactpersoon:" label-for="input-contact">
            <b-form-input
              id="input-contact"
              v-model="form.contact"
              placeholder="Contactpersoon, bijvoorbeeld uw naam"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-contact" label="Adres en huisnummer:" label-for="input-address">
            <b-form-input
              id="input-address"
              v-model="form.address"
              placeholder="Adresgegevens en huisnummer"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-contact" label="Postcode:" label-for="input-postcode">
            <b-form-input
              id="input-postcode"
              v-model="form.postcode"
              placeholder="Postcode"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-contact" label="Plaats:" label-for="input-city">
            <b-form-input
              id="input-city"
              v-model="form.city"
              placeholder="Plaats"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-country"
            label="Land:"
            label-for="input-country"
          >
            <b-form-select
              id="input-country"
              v-model="form.country"
              required
              :options="countryOptions"
              default="NL"
            ></b-form-select>
          </b-form-group>

          <b-form-group id="input-group-contact" label="Interne referentie:" label-for="input-external_reference_id">
            <b-form-input
              id="input-external_reference_id"
              v-model="form.external_reference_id"
              placeholder="Referentie binnen uw organisatie"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-email"
            label="Email adres:"
            label-for="input-email"
          >
            <b-form-input
              id="input-email"
              v-model="form.email"
              placeholder="Het email adres waarop u bereikbaar bent"
              required
              type="email"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-phone"
            label="Telefoonnummer:"
            label-for="input-phone"
          >
            <b-form-input
              id="input-phone"
              v-model="form.phone"
              placeholder="06 of vast nummer"
              required
            ></b-form-input>
          </b-form-group>

          <b-button-group class="mt-2" style="width: 100%">
            <b-button class="mr-2" size="lg" @click="tabIndex--">Wijzig samenstelling</b-button>
            <b-button size="lg" variant="success" @click="tabIndex++">Ga verder ➤</b-button>
          </b-button-group>

        </b-tab>
        <b-tab :active="tabIndex === 3" title="4: Controle & bevestiging" :disabled="form.product_name === 'undecided'">
          <h4>Contactgegevens</h4>
          <b-table-simple responsive>
            <b-tbody>

              <b-tr>
                <b-td>Uw organisatie</b-td>
                <b-td>{{ form.organization }}</b-td>
                <b-td>(<a @click="tabIndex=2">aanpassen</a>)</b-td>
              </b-tr>
              <b-tr>
                <b-td>Contactpersoon</b-td>
                <b-td>{{ form.contact }}</b-td>
                <b-td>(<a @click="tabIndex=2">aanpassen</a>)</b-td>
              </b-tr>
              <b-tr>
                <b-td>Adres en huisnummer</b-td>
                <b-td>{{ form.address }}</b-td>
                <b-td>(<a @click="tabIndex=2">aanpassen</a>)</b-td>
              </b-tr>
              <b-tr>
                <b-td>Postcode</b-td>
                <b-td>{{ form.postcode }}</b-td>
                <b-td>(<a @click="tabIndex=2">aanpassen</a>)</b-td>
              </b-tr>
              <b-tr>
                <b-td>Plaats</b-td>
                <b-td>{{ form.city }}</b-td>
                <b-td>(<a @click="tabIndex=2">aanpassen</a>)</b-td>
              </b-tr>
              <b-tr>
                <b-td>Interne referentie</b-td>
                <b-td>{{ form.external_reference_id }}</b-td>
                <b-td>(<a @click="tabIndex=2">aanpassen</a>)</b-td>
              </b-tr>

              <b-tr>
                <b-td>E-Mail adres</b-td>
                <b-td>{{ form.email }}</b-td>
                <b-td>(<a @click="tabIndex=2">aanpassen</a>)</b-td>
              </b-tr>
              <b-tr>
                <b-td>Telefoon</b-td>
                <b-td>{{ form.phone }}</b-td>
                <b-td>(<a @click="tabIndex=2">aanpassen</a>)</b-td>
              </b-tr>

            </b-tbody>
          </b-table-simple>

          <h4>Samenstelling</h4>
          <b-table-simple responsive v-if="form.product_name === 'icf_premium_support'">
            <b-tbody>
              <b-tr>
                <b-td>Contractsvorm</b-td>
                <b-td>Premium Support</b-td>
              </b-tr>
              <b-tr>
                <b-td>Doel(en) van het project</b-td>
                <b-td><i>{{ form.product_2024_premium_support_goals }}</i> (<a @click="tabIndex=1">aanpassen</a>)</b-td>
              </b-tr>
              <b-tr>
                <b-td>Omvang/scope van het project</b-td>
                <b-td><i>{{ form.product_2024_premium_support_scope }}</i> (<a @click="tabIndex=1">aanpassen</a>)</b-td>
              </b-tr>
              <b-tr>
                <b-td>Gewenste ondersteuning</b-td>
                <b-td><i>{{ form.product_2024_premium_support_statement_of_needed_support }}</i> (<a
                  @click="tabIndex=1">aanpassen</a>)
                </b-td>
              </b-tr>
            </b-tbody>
            <b-tfoot>
              <b-tr variant="success">
                <b-td>
                  &nbsp;
                </b-td>
                <b-td>
                  Wij nemen contact op voor verdere vragen of met een offerte.
                </b-td>
              </b-tr>
            </b-tfoot>
          </b-table-simple>

          <b-table-simple responsive v-if="form.product_name === 'icf_plus_participant'">
            <b-tbody>
              <b-tr>
                <b-td>Abonnementsvorm</b-td>
                <b-td>Plus, incl {{ standard_domains }} domeinen</b-td>
                <b-td>€49,-</b-td>
              </b-tr>
              <b-tr>
                <b-td>Optie: Extra domeinen</b-td>
                <b-td>{{ form.product_2021_extra_domains_x_100 }} (<a @click="tabIndex=1">aanpassen</a>)</b-td>
                <b-td>
                  <span v-if="extra_domain_costs">€{{ extra_domain_costs }},-</span>
                  <span v-if="!extra_domain_costs">€0,-</span>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>Gewenste (hoofd)-domeinen</b-td>
                <b-td>{{ form.domains }}</b-td>
                <b-td>(<a @click="tabIndex=1">aanpassen</a>)</b-td>
              </b-tr>
              <b-tr>
                <b-td>Eventuele opmerkingen</b-td>
                <b-td>{{ form.remarks }}</b-td>
                <b-td>(<a @click="tabIndex=1">aanpassen</a>)</b-td>
              </b-tr>
              <b-tr>
                <b-td>Totaal domeinen</b-td>
                <b-td>{{ total_domains }}</b-td>
                <b-td></b-td>
              </b-tr>
            </b-tbody>
            <b-tfoot>
              <b-tr variant="success">
                <b-td>
                  Indicatie totaalprijs
                </b-td>
                <b-td>
                </b-td>
                <b-td>
                  &euro; <span style="font-size: 2em;">{{ total_price }},-</span> / maand
                </b-td>
              </b-tr>
            </b-tfoot>
          </b-table-simple>

          <h4>Spam-bescherming</h4>
          <!--
          <b-form-checkbox required v-model="form.accepted_terms" :value="true"
                           :unchecked-value="false" name="form.accepted_terms">
            Ik ga akkoord met de <a href="av.pdf" target="_blank">algemene voorwaarden</a>.
          </b-form-checkbox>
          -->
          <b-form-group
            id="input-group-captcha"
            label="Los deze vraag op (bescherming tegen robots): wat is 99 + 1?"
            label-for="input-captcha"
          >
            <b-form-input
              id="input-captcha"
              v-model="form.captcha"
              placeholder="Wat is 99 + 1?"
              required
              type="number"
            ></b-form-input>
          </b-form-group>

          <b-alert :show="captcha_error" variant="danger">Het antwoord op de spam-bescherming is niet juist. Los de
            rekensom op en probeer opnieuw.
          </b-alert>

          <b-alert :show="submit_error" variant="danger">Het formulier kon niet worden verzonden, de backend lijkt niet
            bereikbaar. Neem contact op met <a
              :href="'mailto:info@internetcleanup.foundation?subject=inschrijving&body=' + JSON.stringify(form)">info@internetcleanup.foundation</a>
            als dit probleem aanhoudt.<br>
          </b-alert>


          <b-button-group class="mt-2" style="width: 100%">
            <b-button class="mr-2" size="lg" @click="tabIndex=1">Wijzig samenstelling</b-button>
            <b-button class="mr-2" size="lg" @click="tabIndex=2">Wijzig gegevens</b-button>
            <b-button size="lg" type="submit" variant="success" @click="onSubmit">Aanvragen</b-button>
          </b-button-group>

          <i><small>Genoemde prijzen zijn exclusief BTW.</small></i>
        </b-tab>
      </b-tabs>

    </b-form>

  </div>
</template>

<script>
import http from './../../httpclient';

export default {
  name: "Home.vue",
  data() {
    return {
      tabIndex: 0,

      form: {
        // icf_plus_participant: gewoon deelnemerschap met plus, vast bedrag per maand
        // icf_premium_support: op offertebasis
        product_name: 'undecided',

        // icf_plus_participant
        product_2021_standard_domains: 1,
        product_2021_extra_domains_x_100: 0,

        // icf_premium_support
        product_2024_premium_support_goals: "",
        product_2024_premium_support_scope: "",
        product_2024_premium_support_statement_of_needed_support: "",

        // Contactgegevens
        email: '',
        phone: '',
        organization: '',
        contact: '',
        address: '',
        postcode: '',
        city: '',
        country: 'NL',
        external_reference_id: '',
        domains: '',
        remarks: '',

        // form technology
        accepted_terms: false,
        captcha: 0,
      },

      countryOptions: [
        {value: 'NL', text: 'Nederland'},
        {value: 'BE', text: 'België / Belgique'},
      ],

      standard_domains: 250,
      price_basic: 49,
      domains_per_bundle: 100,
      price_extra_domain_bundle: 5,
      show: true,

      signup_success: false,

      submit_error: false,
      captcha_error: false,
    }
  },
  computed: {
    total_price: function () {
      return this.price_basic + this.extra_domain_costs
    },
    extra_domain_costs: function () {
      return this.price_extra_domain_bundle / this.domains_per_bundle * parseInt(this.form.product_2021_extra_domains_x_100)
    },
    total_domains: function () {
      return this.standard_domains + parseInt(this.form.product_2021_extra_domains_x_100)
    },
  },
  watch: {
    tabIndex(new_value){
      this.$router.push({ query: {tabIndex: new_value}});
    }
  },
  methods: {
    reset() {
      this.form = {
        product_name: 'undecided',

        // icf_plus_participant
        product_2021_standard_domains: 1,
        product_2021_extra_domains_x_100: 0,

        // icf_premium_support
        product_2024_premium_support_goals: "",
        product_2024_premium_support_scope: "",
        product_2024_premium_support_statement_of_needed_support: "",

        // contactgegevens
        email: '',
        phone: '',
        organization: '',
        contact: '',
        address: '',
        postcode: '',
        city: '',
        country: 'NL',
        external_reference_id: '',
        domains: '',
        remarks: '',

        // form technology
        accepted_terms: false,
        captcha: 0,
      };
      this.signup_success = false;
      this.signup_data = {};
      this.tabIndex = 0;
    },

    onSubmit(event) {
      // if (this.form.accepted_terms !== true) {
      //   console.log("Not accepted terms.");
      //   this.error = true;
      //   return
      // }
      if (this.form.captcha !== "100") {
        console.log("Captcha incorrect");
        this.captcha_error = true;
        return
      }

      this.captcha_error = false;
      this.submit_error = false;
      let self = this;

      http.post('/signup/', this.form).then((data) => {
        this.signup_success = true;
        this.signup_data = data.data.data;
      }).catch(function (error) {
        console.log(error);
        self.submit_error = true;
      });
      event.preventDefault();
    }
  }
}
</script>

