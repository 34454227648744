<template>
  <div>
    <b-button size="sm" class="m-2" type="submit" variant="secondary" @click="list_sent_alerts">
      <b-icon v-if="sent_alerts_loading" icon="arrow-clockwise" animation="spin"></b-icon>
      Check for new data
    </b-button>

    <b-table fixed striped hover :fields="sent_alert_fields" :items="sent_alerts" :busy="sent_alerts_loading"
             primary-key="id">
      <template #cell(at_when)="row">
        <small>{{ humanize_date(row.item.at_when) }}, {{ humanize_relative_date(row.item.at_when) }}</small>
      </template>
      <template #cell(media)="row">
        <template v-if="row.item.media.email">
          <small>💌: {{ row.item.media.email.to }}</small>
        </template>
      </template>
    </b-table>
  </div>
</template>

<script>

export default {
  data: function () {
    return {
      server_response: {},
      loading: false,

      sent_alerts_loading: false,
      sent_alerts: Array(),
      sent_alert_fields: [
        {
          key: 'at_when',
          sortable: true,
        },
        {
          key: 'why',
          sortable: true,
        },
        {
          key: 'media',
          sortable: true,
          label: 'media'
        },
      ],
    }
  },
  mounted: function () {
    this.list_sent_alerts();
  },
  methods: {
    list_sent_alerts: function () {
      this.sent_alerts_loading = true;
      this.$http.get('/alerts/list_sent_alerts/').then(data => {
        this.sent_alerts_loading = false;
        this.sent_alerts = data.data
      });
    },
  },
}
</script>
<i18n>
{
  "en": {
  },
  "nl": {
  }
}

</i18n>
