<template>
  <div>
    <h4>{{ $t('severity') }}</h4>
    <p>{{ $t('severity_meaning') }}</p>
    <b-badge variant="danger" style="font-size: 1.5em" class="mr-2" v-if="summary.critical">{{ summary.critical }} Critical Risk</b-badge>
    <b-badge variant="danger" style="font-size: 1.5em" class="mr-2" v-if="summary.high">{{ summary.high }} High Risk</b-badge>
    <b-badge variant="warning" style="font-size: 1.5em" class="mr-2" v-if="summary.medium">{{ summary.medium }} Medium Risk</b-badge>
    <b-badge variant="secondary" style="font-size: 1.5em" class="mr-2" v-if="summary.low">{{ summary.low }} Low Risk</b-badge>
    <b-badge variant="secondary" style="font-size: 1.5em" class="mr-2" v-if="summary.info">{{ summary.info }} Info Risk</b-badge>
    <b-badge variant="secondary" style="font-size: 1.5em" class="mr-2" v-if="summary.unknown">{{ summary.unknown }} Unknown Risk</b-badge>
    <br><br>

    <h4>{{ $t('findings') }}</h4>
    <p>{{ $t('findings_meaning') }}</p>
    <b-tabs>
      <b-tab title="Cards">
        <b-card-group columns>
          <NucleiFinding v-for="finding in findings" :key="finding.key" :finding="finding" />
        </b-card-group>
        </b-tab>
      <b-tab title="Table">
        <NucleiFindingTable :findings="findings" />
      </b-tab>
    </b-tabs>
    <p>{{ $t('total_findings', [findings.length]) }}</p>

    <h4>{{ $t('raw_data') }}</h4>
    <b-textarea
      id="input-organization"
      v-model="findings"
      placeholder=""
      required
      class="decrypted"
      disabled="disabled"
      rows="20"
    ></b-textarea>
  </div>
</template>

<script>
import NucleiFinding from "@/components/sensitive/NucleiFinding";
import NucleiFindingTable from "@/components/sensitive/NucleiFindingTable";
export default {
  name: "PrettyOutputNuclei",
  components: {NucleiFindingTable, NucleiFinding},
  props: {
    data: {type: Object, required: true},
  },
  data() {
    return {
      summary: {},
      findings: [],
    }
  },

  mounted() {
    this.summary = this.data.summary;

    // upgrade findings so they are searchable. which is only able in top-level properties:
    let i = 0;
    this.data.report.forEach(item => {
      item.key = i;
      item.name = item.info.name;
      item.severity = item.info.severity;
      item.description = item.info.description;
      this.findings.push(item);
      i++;
    })
  },

}
</script>

<style scoped>

</style>

<i18n>
{
  "en": {
    "raw data": "Raw data",
    "findings": "Findings",
    "severity": "Risk summary",
    "severity_meaning": "Every scan tool does their own determination of risks. One tool might find something a high risk, while the other thinks it is low. Sometimes multiple low risk issues together create a high risk issue. We recommend to evaluate all results and to determine what needs fixing.",
    "total_findings": "Total: {0} findings",
    "findings_meaning": "Findings may wildly vary in quality and references. As nuclei is a community project it sometimes misses the mark, sometimes it's spot on."
  },
  "nl": {
    "raw data": "Meetgegevens",
    "findings": "Bevindingen",
    "severity": "Samenvatting risicos",
    "severity_meaning": "Iedere scantool heeft eigen risiconiveaus, wat bij de ene tool hoog is, is bij de andere laag. Soms vormen meerdere lage risico's samen een hoge. We raden aan om alle bevindingen na te lopen en te bepalen of dit is op te lossen.",
    "total_findings": "Totaal: {0} bevindingen",
    "findings_meaning": "Bevindingen kunnen sterk wisselen in kwaliteit en onderbouwing. Omdat nuclei een community project is slaat het soms de plank mis, maar soms raakt het ook erg hard."
  }
}

</i18n>
