<template>
  <div>
    <p>{{$t('will_be_editable')}}</p>

    <b-tabs>
      <b-tab class="pt-2">
        <template #title>
          <b-icon icon="person-badge" variant="info"></b-icon>
          {{ $t("Identification") }}
        </template>

        <b-form >


          <b-form-group
            id="input-group-name"
            label="Name:"
            label-for="input-name"
            description="The primary name the organization uses to identify itself."
          >
            <b-form-input
              id="input-name"
              v-model="organization.name"
              type="text"
              placeholder="Enter organization name"
              disabled
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-name"
            label="Alternative Name:"
            label-for="input-name"
            description="A secondary name for which the organization is known."
            v-for="[id, value] in Object.entries(organization.alternative_names)" :key="id"
          >
            <b-form-input
              :id="'input-altname-name-' + value.id"
              v-model="organization.alternative_names[id].name"
              type="text"
              placeholder="Enter alternative organization name"
              disabled
            ></b-form-input>
            <b-form-input
              :id="'input-altname-description-' + value.id"
              v-model="organization.alternative_names[id].description"
              type="text"
              placeholder="Enter alternative organization description"
              disabled
            ></b-form-input>
          </b-form-group>


          <b-form-group
            id="input-group-twitter"
            label="Twitter Handle:"
            label-for="input-twitter"
            description="Primary twitter handle of this organization, including the @"
          >
            <b-form-input
              id="input-twitter"
              v-model="organization.twitter_handle"
              type="text"
              placeholder="Enter Twitter Handle, including @"
              disabled
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-wikidata"
            label="Wikidata Q-Code:"
            label-for="input-wikidata"
            description="Wikidata Q or C code for this organization."
          >
            <b-form-input
              id="input-wikidata"
              v-model="organization.wikidata"
              type="text"
              placeholder="Enter the wikidata code for this organization"
              disabled
            ></b-form-input>
          </b-form-group>


          <b-form-group
            id="input-group-wikipedia"
            label="Wikipedia page:"
            label-for="input-wikipedia"
            description="Wikidata page including wikipedia country code."
          >
            <b-form-input
              id="input-wikipedia"
              v-model="organization.wikipedia"
              type="text"
              placeholder="Wikipedia page including wikipedia country code"
              disabled
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-created_on"
            label="Created on:"
            label-for="input-created_on"
            description="Date on which the organization was created."
          >
            <b-form-input
              id="input-created_on"
              v-model="organization.created_on"
              type="text"
              placeholder="Date on which the organization was created"
              disabled
            ></b-form-input>
          </b-form-group>

        </b-form>

      </b-tab>
      <b-tab class="pt-2">
        <template #title>
          <b-icon icon="map" variant="info"></b-icon>
          {{ $t("Geolocation") }}
        </template>


        <b-form-group
          id="input-group-written_address"
          label="Uitgeschreven adres:"
          label-for="input-written_address"
          description="Volledig uitgeschreven adres zoals op een briefkaart"
        >
          <b-form-input
            id="input-written_address"
            v-model="organization.written_address"
            type="text"
            placeholder="Volledig uitgeschreven adres zoals op een briefkaart"
            disabled
          ></b-form-input>
        </b-form-group>


        <b-form-group
          id="input-group-country"
          label="Land:"
          label-for="input-country"
          description="Primair land van deze organisatie"
        >
          <b-form-input
            id="input-country"
            v-model="organization.country"
            type="text"
            placeholder="Primair land van deze organisatie"
            disabled
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-coordinates"
          label="Geodata:"
          label-for="input-coordinates"
          description="Known geographic coordinate data used on the map."
          v-for="[id, value] in Object.entries(organization.coordinates)" :key="id"
        >
          <b-form-input
            :id="'input-coordinates-geojsontype-' + value.id"
            v-model="organization.coordinates[id].geojsontype"
            type="text"
            placeholder="Soort geografische data"
            disabled
          ></b-form-input>
          <b-form-input
            :id="'input-coordinates-area-' + value.id"
            v-model="organization.coordinates[id].area"
            type="text"
            placeholder="Geografische data in geojson formaat"
            disabled
          ></b-form-input>
        </b-form-group>


        <b-form-group
          id="input-group-layers"
          label="Op de volgende kaart(en):"
          label-for="input-layers"
          description="Op welke kaarten deze organisatie te zien is."
          v-for="[id, value] in Object.entries(organization.layers)" :key="id"
        >
          <b-form-input
            :id="'input-layers-id-' + value.id"
            v-model="organization.layers[id].id"
            type="text"
            placeholder="Layer ID"
            disabled
          ></b-form-input>
          <b-form-input
            :id="'input-layers-name-' + value.id"
            v-model="organization.layers[id].name"
            type="text"
            placeholder="Layer naam"
            disabled
          ></b-form-input>
        </b-form-group>

      </b-tab>
      <b-tab class="pt-2">
        <template #title>
          <b-icon icon="patch-question" variant="info"></b-icon>
          {{ $t("Domain Registration / Whois") }}
        </template>

        <b-form-group
          id="input-group-acceptable_domain_registrants"
          label="Acceptabele registrants:"
          label-for="input-acceptable_domain_registrants"
          description="Lijst met registrants die gebruikt mogen worden, deze zijn herkenbaar door normale mensen: ze zijn makkelijk te vinden in zoekmachines."
        >
          <b-form-textarea
            id="input-acceptable_domain_registrants"
            v-model="organization.acceptable_domain_registrants"
            type="text"
            placeholder=""
            disabled
            rows="6"
          ></b-form-textarea>
        </b-form-group>

        <b-form-group
          id="input-group-computed_domain_registrant_statistics"
          label="Informatie over domeinregistraties:"
          label-for="input-computed_domain_registrant_statistics"
          description="Automatisch berekend"
        >
          <b-form-textarea
            id="input-computed_domain_registrant_statistics"
            v-model="organization.computed_domain_registrant_statistics"
            type="text"
            placeholder="Informatie over domeinregistraties (automatisch berekend)"
            disabled
            rows="20"
          ></b-form-textarea>
        </b-form-group>

        <b-form-group
          id="input-group-structured_reasoning_for_registrant_decisions"
          label="Niet geaccepteerde registrants:"
          label-for="input-structured_reasoning_for_registrant_decisions"
          description="Afgekeurde registrants met redenen, bepaald door de Internet Cleanup Foundation"
        >
          <b-form-textarea
            id="input-structured_reasoning_for_registrant_decisions"
            v-model="organization.structured_reasoning_for_registrant_decisions"
            type="text"
            placeholder=""
            disabled
            rows="6"
          ></b-form-textarea>
        </b-form-group>

      </b-tab>

    </b-tabs>

  </div>
</template>

<script>
export default {
  name: "OrganizationInformation",

  props: {
    organization_id: {type: Number, required: true},
  },

  mounted() {
    this.load_organization_data();
  },

  methods: {
    load_organization_data(){
      this.loading = true;
      this.$http.get(`/portfolio/organization_data/${this.organization_id}/`).then(data => {
        this.organization = data.data.data;
        this.loading = false;
      });
    }
  },

  data() {
    return {
      loading: false,

      organization: {

      },

      test_organization: {
        // identification:
        name: "Internet Cleanup Foundation",
        alternative_names: [
          {'id': 1, 'name': 'Faalkaart', 'description': 'Oude naam'},
          {'id': 2, 'name': 'Hobbyclub', 'description': 'Onzin-naam'},
        ],
        twitter_handle: "@internetcleanupfoundation",
        wikidata: "Q12345",
        wikipedia: "nl:Internet Cleanup Foundation",
        created_on: "2020-01-01T00:00:00",

        // geolocation
        country: 'NL',
        layers: [
          {'id': 1, 'name': 'municipality'},
          {'id': 2, 'name': 'security'}
        ],
        written_address: "Oberon 33, 5221 LT, Den Bosch",
        coordinates: [
          {'id': 1, 'geojsontype': 'Point', 'area': [52.5221, 33]},
          {'id': 2, 'geojsontype': 'Point', 'area': [42.5221, 23]},
          {'id': 3, 'geojsontype': 'Point', 'area': [32.5221, 13]}
        ],

        //
        computed_domain_registrant_statistics: {
          "Gemeente Groningen": {
            "urls": [
              "groningen.nl",
              "haren.nl",
              "tenboer.nl"
            ],
            "amount": 3
          },
          "Whois Privacy Protection Foundation": {
            "urls": [
              "cultuurnotagroningen.nl"
            ],
            "amount": 1
          }
        },
        acceptable_domain_registrants: [
          "Gemeente Groningen"
        ],
        structured_reasoning_for_registrant_decisions: [
          {
            "reason": "empty registrant",
            "registrant": ""
          },
          {
            "registrant": "Whois Privacy Protection Foundation",
            "reason": "bulk removed"
          }
        ]

      }
    }
  }
}
</script>

<style scoped>

</style>
<i18n>
{
  "en": {
    "will_be_editable": "This data is not editable yet, this will be editable in the future."
  },
  "nl": {
    "will_be_editable": "Deze informatie is nog niet te bewerken, dat volgt nog voor een aantal velden."
  }
}
</i18n>
