<template>
  <div>
      <PrettyOutput
      :extension="'txt'"
      :data="'some text'"
    ></PrettyOutput>

    <PrettyOutput
      :extension="'nuclei'"
      :data='[{"template-id": "watchguard-panel", "name": "Watchguard Login Panel - Detect", "finding-name": "nuclei_watchguard_panel", "evidence": {"extracted-results": [], "matched-at": "https://unify.odijmond.nl:443/sslvpn_logon.shtml"}}, {"template-id": "fireware-xtm-user-authentication", "name": "Fireware XTM Login Panel - Detect", "finding-name": "nuclei_fireware_xtm_user_authentication", "evidence": {"extracted-results": [], "matched-at": "https://unify.odijmond.nl:443/sslvpn_logon.shtml"}}]'
    ></PrettyOutput>
  </div>
</template>

<script>
import PrettyOutput from "@/components/sensitive/PrettyOutput";
export default {
  name: "DevPage",
  components: {PrettyOutput}
}
</script>

<style scoped>

</style>
