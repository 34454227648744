<template>
  <div>
    <loading :loading="loading"></loading>
    <template v-for="data in timeline">
      <div v-for="record in data" :key="record.when">
        <h4>{{ humanize_datetime(record.when) }}</h4>
        <p>{{humanize_relative_date(record.when)}}</p>

        <template v-if="record.scans.length > 0">
          <h5>Scans</h5>
          <ul>
            <li v-for="scan in record.scans" :key="scan.id">
              {{scan.endpoint}}: {{ scan.scan_type }} - {{ scan.scan_rating }}
            </li>
          </ul>
        </template>

        <template v-if="record.disappeared_endpoints.length > 0">
          <h5>Disappeared endpoints</h5>
          <p v-for="ep in record.disappeared_endpoints" :key="ep.id">
            {{ ep.summary }}
          </p>
        </template>

        <p v-if="record.url_not_resolvable">Url became not resolvable</p>
        <p v-if="record.is_dead">Url was removed from the database</p>
      </div>
    </template>
  </div>
</template>

<script>

export default {
  props: {
    organization: {
      type: Object
    },
    url: {
      type: String
    },
  },
  data: function () {
    return {
      timeline: [],
      loading: false,
    }
  },
  mounted: function () {
    this.get_timeline();
  },
  methods: {
    get_timeline: function () {
      this.loading=true;
      this.server_response = {};
      this.$http.get(`/portfolio/timeline/${this.organization.id}/${this.url}/`).then(data => {
        this.loading=false;
        this.timeline = data.data;
      });
    },
  },
}
</script>
<i18n>
{
  "en": {
    "title": "Portfolio",
    "intro": "Insights in urls and planned scans..."
  },
  "nl": {
  }
}

</i18n>
