<template>
  <div>

    <loading :loading="loading"></loading>
    <server-response :response="server_response" v-if="server_response"/>

    <h2>{{ $t("instant") }}</h2>
    <p>{{ $t("instant_intro") }}</p>
    <p>
      <b-form-checkbox v-model="form.alerts.instant_high" name="check-button" size="lg" switch>
        {{ $t("instant_high") }}
      </b-form-checkbox>

      <b-form-checkbox v-model="form.alerts.instant_medium" name="check-button" size="lg" switch>
        {{ $t("instant_medium") }}
      </b-form-checkbox>
      <i>{{ $t("instant_low") }}</i>

    </p>

    <h2>{{ $t("daily") }}</h2>
    <p>{{ $t("daily_intro") }}</p>
    <p>
      <b-form-checkbox v-model="form.alerts.daily_high" name="check-button" size="lg" switch>
        {{ $t("daily_high") }}
      </b-form-checkbox>

      <b-form-checkbox v-model="form.alerts.daily_medium" name="check-button" size="lg" button-variant="success"
                       switch>
        {{ $t("daily_medium") }}
      </b-form-checkbox>

      <b-form-checkbox v-model="form.alerts.daily_low" name="check-button" size="lg" switch>
        {{ $t("daily_low") }}
      </b-form-checkbox>
    </p>

    <h3>{{ $t("recipients") }}</h3>
    <p>{{ $t("recipients_intro") }}</p>
    <b-form-textarea
      id="textarea"
      v-model="form.recipients"
      :placeholder="$i18n.t('recipient_example')"
      rows="5"
      max-rows="5"
    ></b-form-textarea>

    <b-input-group class="mt-2">
      <b-button class="mr-2" type="submit" variant="success" @click="onSubmit">{{ $t("save") }}</b-button>
      <testmailbutton v-if="form.recipients.includes('@')"></testmailbutton>
    </b-input-group>

  </div>
</template>

<script>

import Testmailbutton from "@/components/alerts/testmailbutton";

export default {
  components: {Testmailbutton},
  data: function () {
    return {
      form: {
        alerts: {
          instant_high: false,
          instant_medium: false,
          daily_high: false,
          daily_medium: false,
          daily_low: false,
        },
        recipients: ""
      },
      server_response: {},
      loading: false,
    }
  },
  mounted: function () {
    this.get_alert_settings();
  },
  methods: {
    onSubmit(event) {
      this.$http.post('/alerts/settings/save', this.form).then((data) => {
        this.server_response = data.data;
      })
      event.preventDefault();
    },
    get_alert_settings: function () {
      this.loading = true;
      this.$http.get('/alerts/settings/get').then(data => {
        this.loading = false;
        this.form = data.data
      });
    },
  },
}
</script>
<i18n>
{
  "en": {
    "instant": "Instant alerts",
    "instant_intro": "Receive instant alerts when:",
    "instant_high": "New 'high' risk scans (red)",
    "instant_medium": "New 'medium' risk scans (orange)",
    "instant_low": "Instant alerts for low/ok changes are not possible due to high volume of alerts.",
    "daily": "Daily alerts",
    "daily_intro": "Receive daily alerts when:",
    "daily_high": "New 'high' risk scans (red)",
    "daily_medium": "New 'medium' risk scans (orange)",
    "daily_low": "New 'low' risk scans (green)",
    "recipients": "Organization recipients",
    "recipients_intro": "Add up to 5 e-mail addresses of alert recipients.",
    "save": "Save changes",
    "recipient_example": "An example: one line per address: \nsecurity@example.nl\nsoc@example.com\nalerts@example.com"
  },
  "nl": {
    "instant": "Directe meldingen",
    "instant_intro": "Ontvang direct een melding bij een:",
    "instant_high": "Nieuw hoog risico meting (rood)",
    "instant_medium": "Nieuw midden risico meting (oranje)",
    "instant_low": "Directe meldingen op laag/ok risico's zijn niet mogelijk vanwege het hoge volume meldingen.",
    "daily": "Dagelijkse meldingen",
    "daily_intro": "Ontvang samengevat per dag meldingen voor:",
    "daily_high": "Nieuwe hoog risico metingen (rood)",
    "daily_medium": "Nieuwe midden risico metingen (oranje)",
    "daily_low": "Nieuwe laag risico metingen (groen)",
    "recipients": "Ontvangers",
    "recipients_intro": "Voeg tot 5 e-mail adressen toe die deze meldingen ontvangen",
    "save": "Wijzigingen opslaan",
    "recipient_example": "Een voorbeeld: een regel per adres: \nsecurity@example.nl\nsoc@example.com\nalerts@example.com"
  }
}

</i18n>
