<template>
  <div>
    <b-tabs content-class="mt-3">
      <b-tab :title="$t('users')">
        <b-row>
          <b-col cols="6">
            <h3>{{ $t('current_users') }}</h3>
            <list-users/>
          </b-col>
          <b-col cols="6">
            <h3>{{ $t('create_new_user') }}</h3>
            <add-user-form/>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab :title="$t('organizations')">
        <OrderOrganizations/>
        <p>{{$t('only_when_multiple_organizations')}}</p>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import AddUserForm from "@/components/account/AddUserForm";
import ListUsers from "@/components/account/ListUsers";
import OrderOrganizations from "@/components/account/OrderOrganizations";

export default {
  name: "AccountPage",
  components: {OrderOrganizations, ListUsers, AddUserForm},
}
</script>

<i18n>
{
  "en": {
    "current_users": "Current users",
    "create_new_user": "Create new user",
    "users": "Users",
    "organizations": "Organizations",
    "only_when_multiple_organizations": "This feature is only available when you have multiple organizations."
  },
  "nl": {
    "current_users": "Huidige gebruikers",
    "create_new_user": "Nieuwe gebruiker aanmaken",
    "users": "Gebruikers",
    "organizations": "Organisaties",
    "only_when_multiple_organizations": "Deze functie is alleen beschikbaar wanneer er meerdere organisaties zijn."
  }
}
</i18n>
