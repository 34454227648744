<template>
  <div>
    <template v-if="organizations.length > 1">
      <h3>{{ $t('title') }}</h3>
      <p>{{ $t('description') }}</p>
      <loading :loading="loading"></loading>
      <server-response :response="server_response" v-if="server_response" :message="$t('' + server_response.message)"/>
      <b-row>
        <b-col  cols="2">
        <b-button @click="move_up" class="w-100 mb-1">🔼 {{ $t('up') }}</b-button><br>
        <b-button @click="move_down"  class="w-100 mb-3">🔽 {{ $t('down') }}</b-button><br>

        <button class="btn btn-success w-100 mb-2" @click="save_organization_order">{{ $t('save') }}</button>
      </b-col>
      <b-col  cols="10">
        <b-form-group>
          <b-form-radio v-model="selected_organization" name="some-radios" :value="organization.value" v-for="(organization, index) in organizations" :key="organization.value">{{index}}: {{organization.text}}</b-form-radio>
        </b-form-group>
      </b-col>

      </b-row>
    </template>

  </div>
</template>

<script>
export default {
  name: "OrderOrganizations",

  data() {
    return {
      organizations: [],
      selected_organization: -1,
      show_retry: false,
      loading: false,

      server_response: {},
    }
  },

  mounted() {
    this.get_portfolio_organizations();
  },

  methods: {
    get_portfolio_organizations: function () {
      this.loading = true;
      this.$http.get('/portfolio/list_organizations/').then(data => {
        data.data.forEach(organization => {
          this.organizations.push({
            'value': organization.id,
            'text': organization.name
          });
        });
        this.loading = false;
        this.show_retry = false;
      }).catch(() => {
        this.show_retry = true;
      });
    },
    move_up: function () {
      if (this.selected_organization === -1)
        return

      // move the organization in the organizations array to a higher position if possible
      let find_organization = this.organizations.find(org => org.value === this.selected_organization)
      let index = this.organizations.indexOf(find_organization);
      console.log(index)
      if (index > 0) {
        let temp = this.organizations[index];
        this.organizations[index] = this.organizations[index - 1];
        this.organizations[index - 1] = temp;
      }
      this.$forceUpdate()
    },
    move_down: function () {
      if (this.selected_organization === -1)
        return

      // move the organization in the organizations array to a lower position if possible
      let find_organization = this.organizations.find(org => org.value === this.selected_organization)
      let index = this.organizations.indexOf(find_organization);
      if (index < this.organizations.length - 1) {
        let temp = this.organizations[index];
        this.organizations[index] = this.organizations[index + 1];
        this.organizations[index + 1] = temp;
      }
      this.$forceUpdate()
    },

    save_organization_order() {

      let org_ids = this.organizations.map(org => org.value);
      this.loading = true;

      this.$http.post(
        '/account/set_store_preferred_organization_order/', {'organizations': org_ids}
      ).then(data => {
        this.server_response = data.data;
        if (data.data.success) {
          this.$emit('order_changed_account', []);
        }
        this.loading = false;
      });
    }

  },

}
</script>

<i18n>
{
  "en": {
    "title": "Organization order",
    "description": "Use the 'up' and 'down' buttons to determine the order of the organizations in the portfolio. You may need to refresh the portfolio to see the changes.",
    "up": "Move up",
    "down": "Move down",
    "save": "Save",
    "saved_order": "Saved successfully, reload the page to see the changes in the portfolio.",
    "error_in_saving_order": "Error in saving the order, please contact support."
  },
  "nl": {
    "title": "Organisatievolgorde",
    "description": "Gebruik de 'Omlaag' en 'Omhoog' knoppen om de volgorde van de organisaties in het portfolio te bepalen. Na wijzigingen moet de pagina opnieuw geladen worden.",
    "up": "Omhoog",
    "down": "Omlaag",
    "save": "Opslaan",
    "saved_order": "Opgeslagen, herlaad de pagina om de wijzigingen te zien in het portfolio.",
    "error_in_saving_order": "Fout bij het opslaan van de volgorde, neem contact op met support."
  }

}

</i18n>
