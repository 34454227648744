<template>
  <div>
    <h1>{{ $t("title") }}</h1>
    <p> {{ $t('intro') }}</p>

    <server-response :response="server_response"></server-response>

    <p>
      <b-button role="link" @click="visible.add_account = true" variant="success">{{ $t("add_account") }}</b-button>
    </p>

    <p>
      <b-button role="link" @click="get_accounts">🔁 {{ $t("reload_list") }}</b-button>
    </p>

    <p>
      <b-form-input
        v-model="filter"
        type="search"
        id="filterInput"
        placeholder="Type to Search"
      ></b-form-input>
    </p>

    <p v-if="totalRows > perPage">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        pills
        size="sm"
        class="my-0"
      ></b-pagination>
    </p>

    <b-table striped hover selectable
             show-empty
             ref="selectableTable"
             :responsive="'sm'"
             :select-mode="'single'"
             :no-border-collapse="true"
             :items="accounts"
             :fields="fields"
             :primary-key="'id'"
             :busy="loading"
             :filter-included-fields="filterOn"
             :sort-by.sync="sortBy"
             :current-page="currentPage"
             :sort-desc.sync="sortDesc"
             sort-icon-left
             :per-page="perPage"
             :filter="filter"
    >
      <template #empty="">
        <b-alert variant="info" show><b-icon icon="info-circle" /> No data found.</b-alert>
      </template>
      <template #emptyfiltered="">
        <b-alert variant="info" show><b-icon icon="info-circle" /> No data in search results, try a different keyword.</b-alert>
      </template>

      <template #cell(users)="data">
        {{ data.value.join(", ") }}
      </template>

      <template #table-busy>
        <loading :loading="loading"/>
      </template>
    </b-table>

    <add-account-form :visible="visible.add_account" @close="visible.add_account=false;get_accounts();"></add-account-form>
  </div>
</template>

<script>

import AddAccountForm from "@/components/admin/AddAccountForm";
export default {
  components: {AddAccountForm},
  data: function () {
    return {
      fields: [
        {key: "id", sortable: true, label: 'Id'},
        {key: "name", sortable: true, label: 'Name'},
        {key: "users", sortable: true, label: 'Users'},
        {key: "organizations", sortable: true, label: 'Organizations'}
      ],
      sortBy: 'id',
      sortDesc: false,
      filter: "",
      filterOn: ['name', 'id', 'users'],
      perPage: 1000,
      totalRows: 1,
      currentPage: 1,


      visible: {
        add_account: false,
      },

      accounts: [],
      current_account: {},
      server_response: {},
      loading: false,
      selected: [],
      initial_selected: {}
    }
  },
  mounted: function () {
    this.get_accounts();
  },
  methods: {
    get_accounts: function () {
      this.loading = true;
      this.$http.get('/adminr/list_accounts/').then(data => {
        this.accounts = data.data['accounts'];
        this.totalRows = this.accounts.length;
        this.loading = false;
      });
    },
  }
}
</script>
<i18n>
{
  "en": {
    "title": "List Account",
    "add_account": "Add new account",
    "intro": "Add new accounts that get instant access to this website.",
    "reload_page_warning": "Important: refresh the page after choosing an account!",
    "select": "Select account to use, the account is instantly switched",
    "reload_list": "Reload account list",
    "switched_account": "Switched to account {0}. Refresh the page to use this account."
  },
  "nl": {
    "title": "Account toevoegen",
    "add_account": "Nieuw account maken",
    "intro": "Voeg een account en gebruiker toe die gebruik kan maken van deze site.",
    "reload_page_warning": "Let op: herlaad de pagina na het wisselen van account!",
    "select": "Selecteer het account om te gebruiken, wisselen gebeurd direct",
    "reload_list": "Lijst met accounts verversen",
    "switched_account": "Geswitched naar account {0}. Ververs de pagina om dit account te gebruiken."
  }
}
</i18n>
