<style scoped>
li a:any-link {
  color: white;
  text-decoration: none
}

</style>

<template>
   <b-navbar toggleable="lg" type="dark" variant="dark">
     <router-link to="/home" accesskey="h"><b-navbar-brand>Basisbeveiliging+</b-navbar-brand></router-link>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav v-if="user">
        <template v-if="user.is_authenticated === true">
          <b-nav-item to="/portfolio" accesskey="p" exact exact-active-class="active">
            {{ $t("portfolio") }}
          </b-nav-item>
          <b-nav-item to="/findings" accesskey="c" exact exact-active-class="active">
            {{ $t("findings") }}
          </b-nav-item>
          <b-nav-item to="/alerts" accesskey="a" exact exact-active-class="active">
            {{ $t("alerts") }}
          </b-nav-item>
          <b-nav-item to="/sensitivereports" accesskey="s" exact exact-active-class="active">
            {{ $t("sensitive_reports") }}
          </b-nav-item>
          <template v-if="user.is_adminr">
            <b-nav-item to="/add-account" accesskey="a" exact exact-active-class="active">
              <b-icon icon="emoji-heart-eyes"></b-icon>
            </b-nav-item>
          </template>
          <template v-if="user.is_superuser">
            <b-nav-item to="/switch-account" accesskey="a" exact exact-active-class="active">
              <b-icon icon="person-badge"></b-icon>
            </b-nav-item>
            <b-nav-item to="/add-account" accesskey="a" exact exact-active-class="active">
              <b-icon icon="emoji-heart-eyes"></b-icon>
            </b-nav-item>
          </template>
        </template>
        <template v-else>
          <b-nav-item to="/request" accesskey="s" exact exact-active-class="active">
            <b-button variant="success" size="sm">{{ $t("Offerte aanvragen") }}</b-button>
          </b-nav-item>
        </template>
      </b-navbar-nav>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">

        <template v-if="user && user.is_authenticated === true">
          <b-nav-item-dropdown right>
            <template v-slot:button-content>
              <b-icon icon="person-circle"></b-icon>
              {{$store.state.user.account_name}}
            </template>
            <b-dropdown-item @click.native.capture.stop><switch-locale></switch-locale></b-dropdown-item>
            <b-dropdown-item href="/account/change-password/">{{ $t("change_password") }}</b-dropdown-item>
            <b-dropdown-item href="/account/two_factor/">{{ $t("2fa") }}</b-dropdown-item>
            <b-dropdown-item :to="'accounts-users'"><b-icon icon="gear"></b-icon> {{$t("users")}}</b-dropdown-item>
            <b-dropdown-item :variant="$store.state.user.account_public_key ? 'success' :'danger'"  to="/publickey"><b-icon icon="key" /> {{ $t("publickey") }}</b-dropdown-item>
            <b-dropdown-item :variant="$store.state.user.signed_full_scope_waiver ? 'success' :'danger'"  to="/fullscope"><b-icon icon="bug" /> {{ $t("full-scope-waiver") }}</b-dropdown-item>
            <b-dropdown-item @click="logout"><b-icon icon="door-open"></b-icon>{{ $t("log_off") }}</b-dropdown-item>
          </b-nav-item-dropdown>
        </template>
        <template v-else>
          <b-nav-item href="/account/login/" accesskey="a" exact exact-active-class="active">
            <b-icon icon="lock"></b-icon> {{ $t("log_in") }}
          </b-nav-item>
        </template>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>


<script>

import {mapState} from 'vuex'
import SwitchLocale from "@/switchlocale";

export default {
  components: {SwitchLocale},
  methods: {
    logout: function () {
      this.loading = true;
      this.$http.get('session/logout/').then(() => {
        this.loading = false;
        this.status();
      })
    },
    status: function () {
      this.server_response = {};
      this.loading = true;
      this.$http.get('session/status/').then(data => {
        this.$store.commit("set_user", data.data);
        this.loading = false;
        if (!data.data.is_authenticated) {
          this.$bvToast.toast(this.$i18n.t('logged_out_successfully'), {
            title: `✅ ${this.$i18n.t('logged_out_successfully')}`,
            autoHideDelay: 5000,
            variant: 'success',
            solid: true,
            isStatus: false,
            appendToast: false,
          })
          this.$router.push({'name': 'login'});
        }
      })
    },
  },
  computed: mapState(['user']),
}

</script>
<i18n>
{
  "en": {
    "log_in": "Log in",
    "log_off": "Log off",
    "portfolio": "Portfolio",
    "findings": "Findings",
    "alerts": "Alerts",
    "2fa": "Authentication setup",
    "logged_out_successfully": "Logged out successfully!",
    "change_password": "Change password",
    "sensitive_reports": "Sensitive Reports",
    "publickey": "Public Key",
    "Offerte aanvragen": "Deelnemer worden / Offerte aanvragen",
    "full-scope-waiver": "Full-Scope tests",
    "users": "Configuration (users)"
  },
  "nl": {
    "log_in": "Inloggen",
    "log_off": "Uitloggen",
    "portfolio": "Portfolio",
    "findings": "Bevindingen",
    "alerts": "Meldingen",
    "2fa": "Twee-factor login instellingen",
    "logged_out_successfully": "Succesvol uitgelogd!",
    "change_password": "Wachtwoord wijzigen",
    "sensitive_reports": "Gevoelige rapporten",
    "publickey": "Publieke sleutel",
    "Offerte aanvragen": "Deelnemer worden / Offerte aanvragen",
    "full-scope-waiver": "Vrijwaringsverklaring",
    "users": "Instellingen"
  }
}
</i18n>
