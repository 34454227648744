<template>
  <b-button :variant="this.done ? 'success' : 'primary'" @click="send_testmail" :disabled="sent">
    <b-icon v-if="loading" icon="arrow-clockwise" animation="spin"></b-icon>
    <b-icon v-if="done && !loading" icon="check"></b-icon>
    <span v-if="!done && !loading">💌 </span>
    <span v-if="!sent">{{ $t("send") }}</span>
    <span v-if="sent">💌 {{ $t("sent") }}</span>
  </b-button>
</template>

<script>

export default {
  data: function () {
    return {
      operation_response: null,
      loading: false,
      done: false,
      sent: false,
    }
  },
  mounted: function () {
  },
  methods: {
    send_testmail: function () {
      this.loading = true;
      this.$http.post('/alerts/send_testmail/').then(data => {
        this.operation_response = data.data;
        this.loading = false;
        this.done = true;
        if (this.operation_response.success) {
          this.sent = true;
        }
      });
    },
  },
}
</script>
<i18n>
{
  "en": {
    "send": "Send test mail alert",
    "sent": "Mail sent. Check your inbox."
  },
  "nl": {
    "send": "Stuur testmail alert naar bovenstaande adressen",
    "sent": "Testmail verstuurd. Controleer je inbox."
  }
}

</i18n>
