<template>
  <div>
    <h1>{{ $t("title") }}</h1>
    <p>{{ $t("intro") }}</p>

    <template v-if="show_retry">
      <b-button size="sm" class="m-2" type="submit" variant="secondary" @click="get_sensitive_reports">
        <b-icon v-if="loading" icon="arrow-clockwise" animation="spin"></b-icon>
        {{ $t("Retry retrieval") }}
      </b-button>
    </template>

    <b-button size="sm" class="m-2" variant="secondary" @click="get_sensitive_reports" style="float: right">
      <b-icon v-if="!loading" icon="arrow-clockwise"></b-icon>
      <b-icon v-if="loading" icon="arrow-clockwise" animation="spin"></b-icon>
      {{ $t("Reload") }}
    </b-button>

    <SensitiveReportsTable :reports="reports" />

    <loading v-if="loading"></loading>

  </div>
</template>

<script>

import SensitiveReportsTable from "@/components/sensitive/SensitiveReportsTable";
export default {
  components: {SensitiveReportsTable},
  data: function () {
    return {
      reports: [],
      show_retry: false,
      loading: false,
    }
  },
  mounted: function () {
    this.get_sensitive_reports();
  },
  methods: {
    get_sensitive_reports: function () {
      this.server_response = {};
      this.loading = true;
      this.$http.get('/sensitive_reports/list/').then(data => {
        this.reports = data.data;
        this.loading = false;
        this.show_retry = false;
      }).catch(() => {
        this.show_retry = true;
      });
    }
  },
}
</script>
<i18n>
{
  "en": {
    "title": "Sensitive Reports",
    "intro": "These reports are created by tooling that can detect critical vulnerabilities and sensitive data. These reports are encrypted and can only be decrypted by your organization, which is the only one with the key.",
    "Reload": "Check for new reports"
  },
  "nl": {
    "title": "Gevoelige rapporten",
    "intro": "Deze rapporten zijn gemaakt door meetinstrumenten die gevoelige gegevens en kritieke kwetsbaarheden kunnen vinden. Deze rapporten zijn alleen in te zien door uw organisatie, alleen die heeft de sleutel.",
    "Reload": "Zoek naar nieuwe rapporten"
  }
}

</i18n>
