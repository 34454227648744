<template>
  <b-alert show variant="warning" v-if="$store.state.user && $store.state.user.is_authenticated && !$store.state.user.account_public_key">
    <router-link to="/publickey">{{$t('message')}}</router-link>
  </b-alert>
</template>

<script>
export default {
  name: "PublicKeyWarning"
}
</script>

<style scoped>

</style>
<i18n>
{
  "en": {
    "message": "No public key set up. This means you will miss out on sensitive reports such as leaked passwords. For the best experience set up your public key by clicking here."
  },
  "nl": {
    "message": "Geen publieke sleutel gevonden. Dit betekent dat er geen gevoelige rapportage wordt opgehaald. Voor de volledige ervaring zet je een publieke sleutel door hier te klikken."
  }
}
</i18n>
