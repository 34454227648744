<template>
  <div>
    <h1>{{ $t("title") }}</h1>
    <p>{{ $t("intro") }}</p>

    <h2>Step 0: Copy-paste client code that interacts with this API</h2>
    <p>Here is copy-paste code that makes it possible to interact with this API.</p>
    <p>You need the nacl and requests library for this to work.</p>

    <pre>
from typing import Any, Dict, Tuple

import nacl
import nacl.encoding
import nacl.hash
import nacl.utils
import requests
from nacl.public import Box, EncryptedMessage, PrivateKey, PublicKey

API_KEY = "YOUR_API_KEY_HERE"
API_ADDRESS = "https://basisbeveiliging.nl"


def sync_with_basisbeveiliging() -> int:
    account_data = get_data_from_basisbeveiliging_for_documentation()

    vulnerability_reports_sent = 0

    for account_id, account_information in account_data.items():
        for ip in account_information['ips']:
            response = find_and_send_vulnerability(account_information, ip)
            if not response['success']:
                continue

            vulnerability_reports_sent += 1

    return vulnerability_reports_sent


def find_and_send_vulnerability(account_information, ip):
    # This of course can be multiple vulnerabilities, have parameters of what vulns etc...
    data_to_send, metadata = get_data_for_vulnerability(account_information, ip)

    if not data_to_send:
        return {'success': False}

    response = send_data_to_basisbeveiliging(
        account_information=account_information,
        match_on_ip=ip,
        data=data_to_send,
        metadata=metadata
    )

    print(f"Data sent, response was: {response}.")
    return response


def get_data_for_vulnerability(account_information: Dict[str, Any], ip: str) -> Tuple[str, Dict[str, str]]:
    # Here is where the DIVD magic happens
    data = "1337"
    metadata = {"measured_at_when": "2012-12-28T18:06:08-07:00"}
    return data, metadata


def get_data_from_basisbeveiliging_for_documentation():
    """
    {
      1: {
          "public_key": "5Hnb0k5ukZqw9NMyfEVRyCqjNNCdc2EaaUX4O3vtfhU=",
          "urls": ["example.com", "example.nl"],
          "ips": ["1.1.1.1", "1.1.1.2"],
          "id": 1,
      },
    }
    """
    response = requests.post(f"{API_ADDRESS}/api/divd/list_accounts/", {"api_key": API_KEY})
    response.raise_for_status()
    return response.json()


def send_data_to_basisbeveiliging(
        account_information: Dict[str, Any], match_on_ip: str, data: str, metadata: Dict[str, str]
) -> Dict[str, Any]:

    data = bytes(data, 'utf-8')
    encrypted_data, unique_public_key = encrypt_data(account_information['public_key'], data)

    response = requests.post(f"{API_ADDRESS}/api/divd/add_vulnerability_report/", {
        "api_key": API_KEY,
        "account_id": account_information['id'],
        "ip": match_on_ip,
        "account_public_key": account_information['public_key'],
        "uploader_public_key": unique_public_key.encode(encoder=nacl.encoding.Base64Encoder).decode('utf-8'),
        "encrypted_data": encrypted_data,
        "content_hash": nacl.hash.sha256(data, encoder=nacl.encoding.HexEncoder),
        "file_extension": "json",
        "metadata": metadata,
    })

    response.raise_for_status()
    return response.json()


def encrypt_data(receiver_public_key_as_string: str, data: bytes) -> Tuple[EncryptedMessage, PublicKey]:
    receiver_public_key = PublicKey(bytes(receiver_public_key_as_string, 'utf-8'), encoder=nacl.encoding.Base64Encoder)
    throw_away_private_key = PrivateKey.generate()
    throw_away_public_key = throw_away_private_key.public_key
    the_box = Box(throw_away_private_key, receiver_public_key)
    del throw_away_private_key
    encrypted_data = the_box.encrypt(data)
    return encrypted_data, throw_away_public_key

    </pre>


    <p>this is broken down into a few steps:</p>

    <h2>Step 1: retrieval of keys and domains</h2>
    <p>You don't need to get this list every time before sending. Retrieve it once a day for example.</p>

    <p>Send the following POST request to: </p>

    <pre>api/divd/list_accounts/</pre>

    <pre>{"api_key": "your_key_here"}</pre>

    <p>This will return a list of accounts, keys, domains and ip's</p>

    <pre>
{
  # the keys are account id
  1: {
      "public_key": "5Hnb0k5ukZqw9NMyfEVRyCqjNNCdc2EaaUX4O3vtfhU=",
      "urls": ["example.com", "example.nl"],
      "ips": ["1.1.1.1", "1.1.1.2"],
      "id": 1,
  },
  2: {
      "public_key": "1337k5ukZqw9NMyfEVRyCqjNNCdc2EaaUX4O3vtfhU=",
      "urls": ["divd.nl", "example.nl"],
      "ips": ["8.8.8.8", "1.1.1.2"],
      "id": 2
  }
}
    </pre>

    <h2>Step 2: Creating encrypted data</h2>
    <p>To send encrypted data, you will need to encrypt it using the public_key of the account, and your own
    private and public key. You have to destroy your private key after encryption, as there is no use for you
    to be able to decrypt the data again. Only the receiver should be able to do so.</p>

    <p>To encrypt data, look at the following link and copy-paste the sample code and encrypt away: <a href="https://pynacl.readthedocs.io/en/latest/public/#" target="_blank" rel="nofollow">*click*</a></p>

    <p>Here is your checklist:</p>
    <ul>
      <li><input type="checkbox"> A sha256 hash of the content is made before encryption</li>
      <li><input type="checkbox"> Any metadata about the finding that has no implications can be sent as json key-values</li>
      <li><input type="checkbox"> Using libsodium, pynacl, tweetnacl, you copy-pasted the example code</li>
      <li><input type="checkbox"> A new keypair is generated for every report sent, for every account</li>
      <li><input type="checkbox"> The private key made by you is destroyed before sending the encrypted data</li>
      <li><input type="checkbox"> The public key of the account is used for encryption, along with your private key</li>
      <li><input type="checkbox"> Your public key is sent to us, not the private key</li>
    </ul>


    <h3>Step 3: Sending encrypted data</h3>
    <p>To send encrypted data, you'll use base64 encoding. There are a few things to take into account when sending
    data.</p>

    <p>Send this POST request to:</p>
    <pre>/api/divd/add_vulnerability_report</pre>

    <p>Here is the call with the list of parameters:</p>
    <pre>
{
  # The API key as in the first request
  "api_key": "your_key_here".

  # The account id, an integer, as received as keys from the dictionary
  "account_id": 1,

  # The IP address that is relevant for this finding. The report will be connected to all domains for this account
  # that have the same IP address.
  "ip": "127.0.0.1",

  # The public key from the account, as received when requesting the data. A check will happen if the key
  # is still the same, or that it was altered meanwhile (and you need to get the list again)
  # This is in base64 format. The nacl library has encoders for this baked in.
  "account_public_key": "5Hnb0k5ukZqw9NMyfEVRyCqjNNCdc2EaaUX4O3vtfhU=",

  # The public key that was generated when you encrypted the data.
  # Note: You can destroy the private key to make sure only the receiver is able to decrypt the data
  # This is in base64 format. The nacl library has encoders for this baked in.
  "uploader_public_key": "YZUITuRD349cIIqQiurigrRGuAMcR5nc9BwJfhcApzk=",

  # The encrypted data. This is the return value from the 'encrypt' function.
  # This is also in base64 format.
  "encrypted_data": "H77EnruhHB/U/LxzbQBgc27pvL9dkbRiodUl0EG2o9esal8zpIeLrLO8CQ==",

  # The hash of the content of the encrypted data. This is used for deduplication. If you send
  # the same message twice, the second one will be ignored.
  # For example made like this: nacl.hash.sha256(bytes(data), encoder=nacl.encoding.HexEncoder)
  "content_hash": "1706be6c293444756e72b05e4afa9eb1038e552ac6ce058309451ef7ddad7748",

  # Files in json and txt format can be decoded in the web interface. This is convenient (but risky)
  # other file types require a download. If you send json, make sure it's also formatted in a readable way:
  # the reading software has no json parser on board. Everything is served "as is".
  "file_extension": "json",

  # Any metadata that you want to share, which does not imply anything about the essence of the data.
  # This is a json object. Please don't go beyond key-value pairs to keep it simple. Any key-value will be
  # shown in the web interface.
  "metadata": {"measured_at_when": "2012-12-28T18:06:08-07:00"},
}
    </pre>

    <p>Note, if you want to validate above example, you can use the following private key for the receiver:</p>
    <pre>msE6bBArwmNTv7tXzQO5m6URJ2KYxuKbvSKSWdiVFqo=</pre>

    <h3>Responses</h3>
    <p>When everything went well you'll get this response:</p>
    <pre>
{
  "success": true,
  "error": false,
  "message": "file_stored"

  # And some other metadata such as a timestamp
}
    </pre>

  </div>
</template>

<script>
export default {
  name: "ApiDocsPage"
}
</script>

<style scoped>
pre {
    background: #f4f4f4;
    border: 1px solid #ddd;
    border-left: 3px solid #f36d33;
    color: #666;
    page-break-inside: avoid;
    font-family: monospace;
    font-size: 15px;
    line-height: 1.6;
    margin-bottom: 1.6em;
    max-width: 100%;
    overflow: auto;
    padding: 1em 1.5em;
    display: block;
    word-wrap: break-word;
}

</style>
<i18n>
{
  "en": {
    "title": "DIVD Api Docs",
    "intro": "How to add data to the system"
  }
}


</i18n>
