<template>
  <b-badge :variant="variant" v-b-tooltip.hover :title="value">{{value.substring(0,10)}}</b-badge>
</template>

<script>
export default {
  name: "PkiValue",
  props: {
    value: {
      type: String,
    },
    variant: {
      type: String,
      default: "primary",
    }
  }
}
</script>

<style scoped>

</style>
