<template>
  <div>
    <server-response :response="server_response" v-if="server_response"/>

    <div v-if="scan.comply_or_explain_is_explained">
      <p v-if="scan.comply_or_explain_explanation">Current explanation: "{{scan.comply_or_explain_explanation}}".</p>
      <p>The explanation is valid until {{ humanize_date(scan.comply_or_explain_explanation_valid_until) }}. <b-button size="sm" @click="extend_explanation()" variant="primary" class="m-2">Extend explanation</b-button></p>
    </div>

    <b-form-select v-model="selected_canned_explanation" :options="canned_explanations" class="mb-2"
    placeholder="Selecteer een standaarduitleg"
    ></b-form-select>

    <b-textarea v-model="new_explanation" placeholder="Of voer een eigen verklaring in..."
              v-html="scan.comply_or_explain_explanation" class="mb-2"></b-textarea>


    <template v-if="!loading">
      <div v-if="scan.comply_or_explain_is_explained">
        <b-button type="button" variant="danger" @click="remove_explanation()" class="m-2">Remove</b-button>
        <b-button @click="explain()" variant="primary" class="m-2">Alter explanation</b-button>
        <b-button @click="row.toggleDetails" class="m-2">Close</b-button>
      </div>
      <div v-if="!scan.comply_or_explain_is_explained">
        <b-button variant="primary" @click="explain()" class="">Explain</b-button>
        <b-button @click="row.toggleDetails" class="ml-2">Close</b-button>
      </div>
    </template>
    <template v-else>
      <button disabled="disabled" class="defaultbutton modal-default-button">
        <b-icon icon="arrow-clockwise" animation="spin"></b-icon>
        {{ $t("loading") }}
      </button>
    </template>
  </div>

</template>
<script>

export default {

  data: function () {
    return {
      server_response: {},
      new_explanation: "",
      selected_canned_explanation: null,
      canned_explanations: [
        {value: null, text: 'Een keuze uit deze lijst met standaard-verklaringen wordt gekopieerd in het onderstaande veld.'},
        {value: 'trusted_on_local_device_with_custom_trust_policy', text: 'trusted_on_local_device_with_custom_trust_policy'},
        {value: 'device_only_certificate', text: 'device_only_certificate'},
        {value: 'for_devices_only_not_browsers', text: 'for_devices_only_not_browsers'},
        {value: 'for_specialized_applications_only_not_browsers', text: 'for_specialized_applications_only_not_browsers'},
        {value: 'unencrypted_required', text: 'unencrypted_required'},
        {value: 'state_trusted_root_ca', text: 'state_trusted_root_ca'},
      ],
      loading: false,
      scan: {},
    }
  },
  mounted: function () {
    this.refresh_scan_data();
  },

  props: {
    scan_id: {
      type: Number
    },
    scan_type: {
      type: String
    },
    row: {
      type: Object,
    }
  },

  methods: {
    stop: function () {
      this.new_explanation = '';
      this.scan = {};
      this.server_response = {};
    },

    explain() {
      this.loading = true;
      this.$http.post(`comply_or_explain/try_explain/`,
        {
          'scan_id': this.scan.id,
          'scan_type': this.scan.type,
          'explanation': this.new_explanation,
        }).then(data => {
        this.server_response = data.data;
        this.refresh_scan_data();
        this.loading = false;
      });
    },
    remove_explanation() {
      this.$http.get(`comply_or_explain/remove_explanation/${this.scan_id}/${this.scan_type}/`).then(data => {
        this.server_response = data.data;
        this.new_explanation = "";
        this.refresh_scan_data();
      });
    },
    extend_explanation() {
      this.$http.get(`comply_or_explain/extend_explanation/${this.scan_id}/${this.scan_type}/`).then(data => {
        this.server_response = data.data;
        this.refresh_scan_data();
      });
    },
    refresh_scan_data() {
      this.loading = true;
      this.$http.get(`comply_or_explain/get_scan_data/${this.scan_id}/${this.scan_type}/`).then(data => {
        this.scan = data.data;
      });
      this.loading = false;
    },

  },
  watch: {
    selected_canned_explanation: function(new_value){
      if (new_value !== null)
        this.new_explanation = new_value;
    }
  }
}
</script>
