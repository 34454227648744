<template>
  <div>
    <ul>
      <li v-for="user in users" :key="user.username">{{ user.username }}</li>
    </ul>
    <b-button variant="primary" @click="load">{{ $t('reload') }}</b-button>
  </div>
</template>

<script>
export default {
  name: "ListUsers",
  data: function () {
    return {
      loading: false,
      users: [],
    }
  },
  mounted: function () {
    this.load();
  },
  methods: {
    load: function () {
      this.loading = true;
      this.$http.get('/account/list_users/').then(data => {
        this.users = data.data;
        this.loading = false;
      });
    },
  },

}
</script>

<style scoped>

</style>
<i18n>
{
  "en": {
    "reload": "Reload"
  },
  "nl": {
    "reload": "Opnieuw laden"
  }
}

</i18n>
