<style>
body {
  min-height: 100vh;
  position: relative;
  margin: 0;
  padding-bottom: 60px;
  box-sizing: border-box;
}

footer {
  position: absolute;
  bottom: 0;
  height: 60px;
  width: 100%;
}
</style>

<template>
  <div id="app">
    <header>
      <site-menu></site-menu>
    </header>

    <b-container class="mt-4">

      <b-alert show variant="danger" v-if="$store.state.network_issue">
        Kan geen verbinding maken met basisbeveiliging. Controleer je internetverbinding. <span v-if="heartbeat_last_attempt">Laatste poging: {{humanize_datetime(heartbeat_last_attempt)}}</span>
      </b-alert>

      <PublicKeyWarning />

      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </b-container>

    <footer class="footer mt-auto py-3">
      <div class="container">
        <span class="text-muted">Basisbeveiliging+ <span v-html="random_slogan()"></span></span>
      </div>
    </footer>
  </div>
</template>

<script>

import SiteMenu from './components/site-menu.vue'
import PublicKeyWarning from "@/components/sensitive/PublicKeyWarning";

export default {
  i18n: {
    locale: 'en',
    fallbackLocale: 'en',
    silentFallbackWarn: true,
  },
  mounted: function () {
    this.login_status();
    this.heartbeat();
  },
  name: 'App',
  data: function () {
    return {
      // server-side config:
      heartbeat_last_attempt: null,
    }
  },
  methods: {
    login_status: function () {
      this.server_response = {};
      this.loading = true;

      this.$http.get(`/session/status/`).then(data => {
        this.$store.commit("set_user", data.data);
        this.loading = false;
      });
    },
    random_slogan: function () {
      let slogans = [
        ' - past in een gezond en gevarieerd dieet.',
        ' - dermatologisch getest.',
        ' - aanbevolen door 9 van de 10 tandartsen.',
        ' - vraag uw arts naar mogelijke bijwerkingen.',
        ' - wij zijn er voor jou!',
        ' - 0% kleurstoffen 0% parfum.',
        ' - nieuw en verbeterd +30% meer geconcentreerde formule.',
        ' - werkt direct en 24 uur lang.',
        ' - ontwikkeld met dermatologen.',
        ' - bel nu, we zitten voor u klaar, maar wacht: er is meer!',
        ' - nu met 20% extra inhoud.',
        ' - niet getest op dieren.',
        ' - met vernieuwd recept.',
        ' - voor de gevoelige huid.',
        ' - dé oplossing voor een droge nacht.',
        ' - laat geen sporen of strepen achter.',
        ' - laat wasmachines langer leven.',
        ' - gemaakt met FSC of gerecycled hout.',
        ' - verpakt onder beschermde atmosfeer.',
        ' - bezoek onze showroom en laat u verassen!',
        ' - met houtskoolextract!',
        ' - revitaliserend en hydraterend',
        ' - zo pats boem lekker luchtdicht, het is wraptastic!',
        ' - bestel nu en krijg de tweede voor twee keer de helft!',
        ' - al twee weken een begrip in heel Nederland',
        ' - yiehahubhubbarbatruukyipkayeemofo',
        ' - BPA en PFAS vrij',
        ' - Wir Leben CYBER',
        ' - wij van Basisbeveiliging adviseren: Basisbeveiliging+',
        ' - traditioneel ontwikkeld',
        ' - ambachtelijke broncode, eerlijke prijzen'
      ]
      return slogans[Math.floor(Math.random() * slogans.length)]
    },
    heartbeat: function() {
      let repeat_speed = 10000;
      if (this.$store.state.network_issue){
        repeat_speed = 2000;
      }
      setTimeout(() => {this.perform_heartbeat(); this.heartbeat()}, repeat_speed)
    },
    perform_heartbeat: function() {
      let d = new Date();
      this.heartbeat_last_attempt = d.toISOString();
      this.$http.get(`/session/heartbeat/`);
    }
  },
  components: {
    PublicKeyWarning,
    SiteMenu
  },

}
</script>

<style>

</style>
<i18n>
{
  "en": {
    "base": {

    }
  },
  "nl": {
    "base": {

    }
  }
}
</i18n>
