<template>
  <div>
    <b-button :variant="variant" size="sm" v-b-modal.plan-full-scope-scan-modal @click="configureFullScopeScan">
      <b-icon v-if="loading" icon="arrow-clockwise" animation="spin"></b-icon>
      <b-icon v-if="done && !loading  && variant === 'success'" icon="check"></b-icon>
      <b-icon v-if="done && !loading && variant === 'danger'" icon="exclamation-octagon"></b-icon>
      <b-icon v-if="!done && !loading" icon="bug"></b-icon> {{ $t("fullScan") }}
    </b-button>
  </div>

</template>
<script>
import http from "@/httpclient";

export default {
  props: {
    organization: {
      type: Number
    },
    url: {
      type: String
    }
  },
  data: function () {
    return {
      loading: false,
      variant: "secondary",
      done: false,
    }
  },
  methods: {
    configureFullScopeScan(){
      this.$store.state.fullscopetest = {
        url: this.url,
        organization: this.organization
      }
    },
    rescan: function () {
      this.loading = true;
      http.post(`/portfolio/fullscan/`,
        {
          'url': this.url,
          'organization_id': this.organization,
        }).then((data) => {
        this.loading = false;
        if (data.data.success === true) {
          this.variant = "success";
        } else {
          this.variant = "danger";
        }
        this.done = true;
      });
    },
  }
}
</script>
<i18n>
{
  "en": {
    "fullScan": "Plan Full Scope Test"
  },
  "nl": {
    "fullScan": "Plan Full-scope Test"
  }
}
</i18n>
