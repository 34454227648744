import VueI18n from "vue-i18n";
import Vue from "vue";
// todo: lazy loading.
import en_locales from './locales/en.json'
Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  silentFallbackWarn: true,
  // it's requformatDistanceired this is called messages.
  messages: {
    en: en_locales,
    nl: en_locales
  },
  sharedMessages: {}
});

export default i18n
