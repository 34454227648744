<template>
  <div>
    <p>Voordat een full-scope test kan worden gestart is het noodzakelijk het volgende te regelen.</p>

    <p>
      Deze voorbereiding is nodig om te voorkomen dat de test wordt geblokkeerd door beveiligingsmaatregelen en dus
      een onvolledig resultaat oplevert. Ook wordt voorkomen dat de verantwoordelijken voor de beveiliging
      (van andere organisaties) onnodig acties gaan uitvoeren. Een full-scope test
      wordt zonder voorbereiding gezien als een aanval.
    </p>

    <p>Door verder te gaan verklaar ik het volgende te hebben geregeld:</p>
    <b-form-checkbox-group
      id="checkbox-group-2"
      v-model="checks"
      name="pretest_checks"
    >
      <ol>
        <li>
          <b-form-checkbox
            value="organization_accepts"
            @change="checks_complete()"
          >
            Er is toestemming verleend om te scannen door de organisatie waarvoor wordt gewerkt
            ({{ $store.state.user.account_name }}).
          </b-form-checkbox>
        </li>
        <li>
          <b-form-checkbox
            value="hoster_accepts_scan"
            @change="checks_complete()"
          >
            De eigenaar van de infrastructuur (de hoster) heeft toestemming gegegeven om te mogen scannen.
          </b-form-checkbox>
        </li>
        <li>
          <b-form-checkbox
            value="organization_accepts_time"
            @change="checks_complete()"
          >
            Er is met de eigenaar overlegd over het tijdstip van de scan, en het is bekend dat dit tijdstip kan afwijken
            afhankelijk van drukte.
          </b-form-checkbox>
        </li>
        <li>
          <b-form-checkbox
            value="firewall_configured"
            @change="checks_complete()"
          >
            Er is in de firewall en andere middelen aangegeven dat de scan van de IP adressen van basisbeveiliging.nl wordt uitgevoerd.<br>
            <pre class="pb-0 mb-0">
  basisbeveiliging.nl: 185.71.61.127, 2a03:38a0:61:127::1
  plus.basisbeveiliging.nl: 37.97.145.112, 2a01:7c8:aabd:148::1</pre>
          </b-form-checkbox>
        </li>
        <li>
          <b-form-checkbox
            disabled
            value="organization_public_key_set"
          >
            Een
            <router-link to="publickey">publieke sleutel</router-link>
            is ingevuld in je profiel. Let op: wij weten nooit welke bevindingen worden gedaan.
          </b-form-checkbox>
        </li>
        <li>
          <b-form-checkbox
            disabled
            value="organization_waiver_signed"
          >
            De
            <router-link to="fullscope">vrijwaringsovereenkomst</router-link>
            is getekend, ingestuurd en afgehandeld door de Internet Cleanup Foundation.
          </b-form-checkbox>
        </li>
      </ol>
    </b-form-checkbox-group>

  </div>
</template>

<script>
export default {
  name: "FullScopePreTestChecklist",
  data() {
    return {
      checks: [].concat(
        [this.$store.state.user.account_public_key !== '' ? 'organization_public_key_set' : '']).concat(
        [this.$store.state.user.signed_full_scope_waiver ? 'organization_waiver_signed' : '']
      )
    }
  },
  methods: {
    checks_complete() {
      if (this.checks.includes("organization_public_key_set"
      ) && this.checks.includes("organization_accepts_time"
      ) && this.checks.includes("hoster_accepts_scan"
      ) && this.checks.includes("organization_accepts"
      ) && this.checks.includes("firewall_configured"
      ) && this.checks.includes("organization_waiver_signed")){
        this.$emit("compliant")
        console.log("compliant")
      } else {
        this.$emit("not_compliant")
      }
    }
  }
}
</script>

<style scoped>

</style>
