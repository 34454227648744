<template>
  <div>
    <h2><b-icon icon="key" /> {{$t('title')}}</h2>
    <p>{{$t('description')}}</p>

    <p>
      Lees hier de <a href="https://internetcleanup.foundation/2023/05/full-scope-tests-voor-deelnemers/">handleiding over hoe dit werkt</a> en wat je er mee kan.
    </p>
    <p>
      Gevorde gebruikers kunnen ook andere platforms gebruiken om een set sleutels te genereren. Bijvoorbeeld op de volgende site:
    <a href="https://tweetnacl.js.org/#/box" target="_blank" ref="nofollow">https://tweetnacl.js.org/#/box</a>.</p>

    <p>Voorbeelden:</p>
    <pre>n0eS2t72IGuKovl3DDnEHIrVFGxymFduVd1hqbKoCRA=</pre>
    <pre>JstVe+RBAU6wfvkjU/M7UUhi7zKz3ak3cobHzvFGqmM=</pre>

    <SetupPublicKeyForm />
  </div>
</template>

<script>
import SetupPublicKeyForm from "@/components/sensitive/SetupPublicKeyForm";
export default {
  name: "PkiValue",
  components: {SetupPublicKeyForm},
  props: {
    value: {
      type: String,
    },
    variant: {
      type: String,
      default: "primary",
    }
  }
}
</script>

<style scoped>

</style>
<i18n>
{
  "en": {
    "title": "Setup Public Key",
    "description": "Your public key is a piece of public information that is used to encrypt sensitive data. Only the holder of the private key can then decrypt data: this is you. Nobody else is able to view the contents of the encrypted file."
  },
  "nl": {
    "title": "Publieke sleutel instellen",
    "description": "Je publieke sleutel wordt gebruikt om gevoelige informatie versleuteld op te slaan. Alleen jouw prive sleutel geeft toegang tot die data, voor de rest kan niemand het zien."
  }
}
</i18n>
