<template>
  <div>
    <p>
      <b-form-input
        v-model="filter"
        type="search"
        id="filterInput"
        placeholder="Type to Search"
      ></b-form-input>
    </p>

    <p v-if="totalRows > perPage">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        pills
        size="sm"
        class="my-0"
      ></b-pagination>
    </p>

    <b-table striped hover selectable
             show-empty
             ref="selectableTable"
             :responsive="'sm'"
             :select-mode="'single'"
             :no-border-collapse="true"
             :items="findings"
             :fields="fields"
             :primary-key="'key'"

             :filter-included-fields="filterOn"
             :sort-by.sync="sortBy"
             :current-page="currentPage"
             :sort-desc.sync="sortDesc"
             sort-icon-left
             :per-page="perPage"
             :filter="filter"
    >
      <template #empty="">
        <b-alert variant="info" show>
          <b-icon icon="info-circle"/>
          No data found.
        </b-alert>
      </template>
      <template #emptyfiltered="">
        <b-alert variant="info" show>
          <b-icon icon="info-circle"/>
          No data in search results, try a different keyword.
        </b-alert>
      </template>

      <template #cell(name)="data">
        <b><b-badge :variant="severity_color(data.item.info.severity)">{{data.item.info.severity}}</b-badge> {{data.item.info.name}}</b><br>
        {{data.item.info.description}}
      </template>

      <template #cell(discovery)="data">
        <template v-if="data.item.type">Type: {{data.item.type}}<br></template>
        <template v-if="data.item['matcher-name']">Matcher: {{data.item['matcher-name']}}<br></template>
        <template v-if='data.item["matched-at"]'>Where: {{data.item["matched-at"]}}<br></template>
        <template v-if='data.item["extracted-results"]'>Finding: {{data.item["extracted-results"]}}<br></template>
      </template>

      <template #cell(references)="data">
        <template v-if="data.item.info.reference">
          <template v-for="url in data.item.info.reference">
            <a :href="url" target="_blank" rel="nofollow" :key="url" class="mr-2">{{ hostname(url) }}</a>
          </template><br>
        </template>

        <template v-if="data.item.info.classification">
          <b-badge v-if='data.item.info.classification["cve-id"]'>{{data.item.info.classification["cve-id"].join(", ")}}</b-badge>
          <b-badge v-if='data.item.info.classification["cwe-id"]'>{{data.item.info.classification["cwe-id"].join(", ")}}</b-badge>
          <b-badge v-if='data.item.info.classification["cvss-metrics"]'>{{data.item.info.classification["cvss-metrics"]}}</b-badge>
        <br><br>
      </template>

      </template>

    </b-table>
  </div>
</template>

<script>
export default {
  name: "NucleiFindingTable",

  props: {
    findings: {type: Array},
  },

  data() {
    return {

      fields: [
        {key: "name", sortable: true, label: 'Name'},
        {key: "discovery", sortable: false, label: 'Discovery'},
        {key: "references", sortable: false, label: 'References'},

      ],

      sortBy: 'info.name',
      sortDesc: false,
      filter: "",
      filterOn: ['name', 'description', 'severity'],
      perPage: 50,

      currentPage: 1,

    }

  },

   methods: {
    hostname(link) {
      // All other solutionos such like with Url or createelement have design side effects, wider columns etc in ffx
      return link.match(/(\w+)\.\w{2,}(\/|\?|$)/)[1];
    },
     severity_color(severity) {
      if (severity === "critical") return "danger";
      if (severity === "high") return "danger";
      if (severity === "medium") return "warning";
      if (severity === "low") return "secondary";
      if (severity === "info") return "secondary";
      if (severity === "unknown") return "secondary";
      return "secondary"
    }

  },

  computed: {
    totalRows() {
      return this.findings.length;
    },

  }
}
</script>

<style scoped>

</style>
