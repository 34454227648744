<template>
  <div>
      <h1>{{ $t("title") }}</h1>
      <p>{{ $t("intro") }}</p>
      <server-response :response="server_response" :message="$t(server_response.message)"></server-response>

      <div v-if="!user || !user.is_authenticated">
        <p>
          Log in here: <a :href="$store.state.plus_endpoint + '/account/login/'">
            {{ $store.state.plus_endpoint }}/account/login/</a>
        </p>

      </div>
      <div v-else>
        {{ $t('logged_in') }}
      </div>
  </div>
</template>

<script>

import {mapState} from 'vuex'

export default {
  data: function () {
    return {
      loading: false,
      server_response: {},
    }
  },
  mounted: function () {
    this.login_status();
  },
  methods: {
    login_status: function () {
      this.server_response = {};
      this.loading = true;
      this.$http.get('/session/status/').then(data => {
        this.$store.commit("set_user", data);
        this.loading = false;
        if (this.user.is_authenticated) {
          this.server_response = {};
          // https://stackoverflow.com/questions/62462276/how-to-solve-avoided-redundant-navigation-to-current-location-error-in-vue
          if (this.$router.name !== 'portfolio') {
            this.$router.push({'name': 'portfolio'}).catch(() => {});
          }
        }
      })
    },
  },
  computed: mapState(['user']),
}
</script>
<i18n>
{
  "en": {
    "title": "Login",
    "intro": "Use the following link to log-in:"
  },
  "nl": {
    "title": "Inloggen",
    "intro": "Gebruik de onderstaande link om in te loggen:"
  }
}

</i18n>
