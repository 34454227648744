<template>
  <div>
    <h2><b-icon icon="bug" /> Full-scope tests</h2>
    <p>
      Met Basisbeveiliging+ is het mogelijk full-scope tests te starten. Deze tests zijn geautomatiseerd en bevatten resultaten
      die niet publiek op basisbeveiliging mogen worden getoond. Het zijn wel basis-metingen, gezien er geen denkwerk aan
      te pas komt. Full-scope tests worden uitgevoerd met bekende test tools die algemeen verkrijgbaar zijn.
    </p>
    <p>
      Voor het uitvoeren van full-scope testen is een vrijwaringsverklaring nodig. Je kan <a href="/static_frontend/av/FullScopeTestWaiver20230522.pdf">de Full Scope Test Waiver hier vinden: klik</a>.
      Onderteken deze namens de deelnemende organisatie en stuur deze op naar: <a href="mailto:info@internetcleanup.foundation?subject=FullScopeWaiver">internetcleanup.foundation</a>.
    </p>

    <b-alert variant="danger" show v-if="$store.state.user.signed_full_scope_waiver === false">
      De vrijwaring voor full-scope-tests is nog NIET getekend. <br>Hierdoor is nog niet mogelijk om full-scope testen uit te voeren.
      Vul de <a href="/static_frontend/av/FullScopeTestWaiver20230522.pdf">de Full Scope Test Waiver</a> in en stuur em op
      naar <a href="mailto:info@internetcleanup.foundation?subject=FullScopeWaiver">internetcleanup.foundation</a>. Na goedkeuring
      is het mogelijk full scope tests te starten.
    </b-alert>
    <b-alert variant="success" show v-else>
      De vrijwaring voor full-scope tests is getekend. Het is mogelijk om deze tests te starten vanuit het <router-link to="portfolio">portfolio</router-link>.<br>
      Je kan deze op ieder moment intrekken door een e-mail te sturen naar
      info@internetcleanup.foundation. Eventueel geplande scans worden dan nog uitgevoerd.
    </b-alert>

    <h3>Wat is een full-scope test?</h3>
    <p>
      Een full-scope test is een geautomatiseerde security test die verder gaat dan de basis. Hiermee kunnen ernstige
      kwetsbaarheden aan het licht komen. Omdat deze test eruit ziet als een aanval op de infrastructuur, is het noodzakelijk
      dat hiervoor een vrijwaringsverklaring te tekenen. Hierdoor wordt de Internet Cleanup Foundation gevrijwaard
      van enige schade die voortvloeit uit deze test.
    </p>

    <h3>Wat kan een full-scope tests?</h3>
    <p>
      Full-scope tests zijn volledig geautomatiseerd en zijn daarom erg makkelijk uit te voeren voor zowel een aanvaller
      als een organisatie als de onze. We bieden deze aan zodat je kan zien wat een eenvoudige aanvaller kan zien en meten.
      Er zijn allerlei soorten automatische scan-tools. Naarmate de stichting groter wordt voegen we meer van deze tools toe.
      Deze tools gebruiken namelijk ook behoorlijk wat capaciteit.
    </p>

    <h3>Waar worden full-scope tests uitgevoerd?</h3>
    <p>
      Full-scope tests worden uitgevoerd vanaf de infrastructuur van de Internet Cleanup Foundation. Dit is op dit moment
      hetzelfde adres als basisbeveiliging.nl.
    </p>

    <h3>Voor wie zijn full-scope tests?</h3>
    <p>Deze scans zijn alleen bedoeld voor deelnemers van de Internet Cleanup Foundation.</p>

    <h3>Wat zijn de voorwaarden voor gebruik?</h3>
    <p>
      Voordat het mogelijk is om full-scope tests uit te voeren is een vrijwaringsverklaring nodig. Daarna zijn
      er geen beperkingen voor het starten van deze scans op je portfolio, behalve fair use. De scan wordt
      zo snel mogelijk gestart nadat deze is aangevraagd: dit kan binnen een uur zijn maar bij drukte ook de volgende dag
      bijvoorbeeld.
    </p>

    <h3>
      Waar staan de resultaten?
    </h3>
    <p>
      De resultaten van de full-scope tests staan bij de <router-link to="sensitivereports">gevoelige rapporten, hier</router-link>.
    </p>

    <h3>
      Wie mag een full-scope test vrijwaring tekenen?
    </h3>
    <p>
      Alleen een tekengerechtigde van de organisatie. Deze moet toestemming hebben van de juiste mensen bij de ICT afdeling
      cq leveranciers om tests te mogen starten.
    </p>

    <h3>
      Wanneer mag ik een full-scope test starten?
    </h3>
    <p>Onderstaand formulier wordt getoond wanneer een full scope test wordt aangevraagd. Alle vragen moeten positief worden beantwoord voordat de test wordt toegevoegd.</p>
    <FullScopePreTestChecklist />

  </div>
</template>

<script>
import FullScopePreTestChecklist from "@/components/fullscopetests/FullScopePreTestChecklist";
export default {
  name: "TestingDisclaimer",
  components: {FullScopePreTestChecklist}
}
</script>

<style scoped>

</style>
