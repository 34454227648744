<template>
  <div>

    <p v-if="!reports.length"> {{ $t('empty') }} </p>

    <b-table :items="reports" :busy="!reports.length" :fields="fields">
      <template #empty>
        <h4>{{ $t('empty') }}</h4>
      </template>
      <template #emptyfiltered="">
        <h4>Filtered too much :)</h4>
      </template>

      <template #cell(our_pk)="data">
        Ours: <PkiValue variant="secondary" :value="data.value"/><br>
        Yours: <PkiValue variant="primary" :value="data.item.their_pk"/>
      </template>

      <template #cell(type)="data">
        Data: {{ data.item.type }}<br>
        Extensie: {{data.item.file_extension}}
      </template>

      <template #cell(urls)="data">
        {{data.value.length}}: {{ data.value.join(", ").substring(0, 100) }}<br>
      </template>

      <template #cell(rating_determined_on)="data">
        Meting op: {{ humanize_date(data.item.last_scan_moment)}}<br>
        Sinds: {{ humanize_date(data.value)}}
      </template>

      <template #cell(id)="row">
        <b-button size="sm" @click="row.toggleDetails" class="mr-2">
            {{ row.detailsShowing ? 'Hide' : 'Show'}} Details
        </b-button>
      </template>

      <template #row-details="data">
        <b-card>
          <FileDecryptionDialog :report_id="data.item.id" :wsm_public_key="data.item.our_pk" :nonce="data.item.nonce" :extension="data.item.file_extension"/>


          <b-button size="sm" variant="success" target="_blank" :href="`${$store.state.plus_endpoint}/api/sensitive_reports/report/download/${data.item.id}`" class="mr-2"><b-icon icon="download" /> {{$t('download_report')}}</b-button>

          <b-button size="sm" @click="data.toggleDetails">{{$t('close')}}</b-button>
        </b-card>
      </template>

    </b-table>

  </div>
</template>

<script>
import PkiValue from "@/components/sensitive/PkiValue";
import FileDecryptionDialog from "@/components/sensitive/ReportDecryptionDialog";
export default {
  name: "SensitiveReportsTable",
  components: {FileDecryptionDialog, PkiValue},
  data() {
    return {
      fields: [
        {key: 'id', label: ''},
        {key: 'type', label: 'Information'},
        {key: 'rating_determined_on', label: 'History'},
        {key: 'our_pk', label: 'Public Keys'},
        {key: 'urls', label: 'Domains'},
      ]
    }
  },

  props: {
    reports: {
      type: Array,
    }
  }
}
</script>

<style scoped>

</style>

<i18n>
{
  "en": {
    "empty": "No reports found. This can happen because there are no reports stored yet. Reports are only stored in the database if they can be encrypted using your encryption key.",
    "close": "Hide Details",
    "download_report": "Download Report"
  },
  "nl": {
    "empty": "Geen rapporten gevonden. Het kan zijn dat er nog geen rapporten zijn, dat kan een paar dagen duren. Rapporten worden alleen toegevoegd als deze versleuteld kunnen worden opgeslagen met uw _publieke_ sleutel.",
    "close": "Details sluiten",
    "download_report": "Rapport Downloaden"
  }
}
</i18n>
