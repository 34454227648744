<template>
  <b-select v-model="selectedlocale">
    <option
      v-for="lang in languages"
      :key="lang"
      :value="lang">
      {{ languagemapping[lang] }}
    </option>
  </b-select>
</template>
<script>
export default {
  data() {
    return {
      languages: ['en', 'nl'],
      // cant use i18n here, because then switching doesn't work anymore. And because it's always in the native language
      // translations always are the same.
      languagemapping: {
        "en": "English",
        "nl": "Nederlands",
      },
      selectedlocale: 'en',
    }
  },
  mounted: function(){
    // prefer saved locale over default locale
    this.selectedlocale = this.$store.state.locale ? this.$store.state.locale : this.$i18n.locale;
  },
  watch: {
    selectedlocale: function(locale) {
      document.cookie = "plus_language=" + (locale || "en") + "; path=/; SameSite=Lax;";
      this.$i18n.locale = locale;
      this.$store.state.locale = locale;
      this.$store.commit("set_locale", locale);

    }
  }
}
</script>
