<template>
  <div>
  <b-textarea v-if="extension === 'txt' || extension === 'json' || extension === 'csv'"
    id="input-organization"
    v-model="data"
    placeholder=""
    required
    class="decrypted"
    disabled="disabled"
    ></b-textarea>

  <template v-if="extension === 'nuclei' && parsed_data !== null">
    <PrettyOutputNuclei :data="parsed_data" />
  </template>
  </div>

</template>

<script>
import PrettyOutputNuclei from "@/components/sensitive/PrettyOutputNuclei";
export default {
  name: "PrettyOutput",
  components: {PrettyOutputNuclei},
  props: {
    data: {type: String, required: true},
    extension: {type: String, required: false, default: "txt"},
  },
  data() {
    return {
      parsed_data: null,
    }
  },

  mounted(){
    if (this.extension === "nuclei")
      this.parsed_data = JSON.parse(this.data);
  },

}
</script>

<style scoped>

</style>
