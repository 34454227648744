<template>
  <div>
    <h1>{{ $t("title") }}</h1>
    <p>{{ $t("intro") }}</p>


    <b-tabs content-class="mt-3">
      <b-tab :title="$i18n.t('settings')" active>
        <AlertSettings class="mb-4"></AlertSettings>
      </b-tab>
      <b-tab :title="$i18n.t('history')" lazy>
        <AlertList></AlertList>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>

import AlertList from './alert_list';
import AlertSettings from './alert_settings';

export default {
  components: {
    AlertList,
    AlertSettings,
  },
  data: function () {
    return {}
  },
}
</script>
<i18n>
{
  "en": {
    "title": "Alerts",
    "intro": "Receive alerts on a certain condition",
    "settings": "Settings",
    "history": "Alert History"
  },
  "nl": {
    "title": "Meldingen",
    "intro": "Stuur meldingen bij nieuwe bevindingen",
    "settings": "Instellingen",
    "history": "Verstuurde meldingen"
  }
}
</i18n>
