<template>
  <b-modal id="plan-full-scope-scan-modal" size="xl">
    <h3 slot="modal-title">
      <b-icon icon="bug"/>
      {{ $t("title") }}
    </h3>

    <loading :loading="loading"></loading>
    <server-response :response="server_response" v-if="server_response"/>

    <p>Start een full-scope test op het volgende domein:</p>
    <b-input :rows="1" disabled :value="$store.state.fullscopetest.url"/>

    <p>De test wordt uitgevoerd met de volgende tool:</p>
    <b-form-select v-model="selected_scanner" :options="options" @change="can_start()"></b-form-select>

    <p>
      De test wordt na uitvoering toegevoegd aan de
      <router-link to="sensitivereports">versleutelde rapporten</router-link>
      .
    </p>

    <div class="m-2">
      <b-button v-b-toggle.collapse-1 variant="primary">Wat kunnen deze scanners?</b-button>
      <b-collapse id="collapse-1" class="mt-2 p-2" style="border: 1px solid silver; border-radius: 5px">
        <b-tabs>
          <b-tab title="Nuclei">
            <p>
              Nuclei is een scanner die ongeveer 6000 checks uitvoert. Het is op dit moment een van de actiefste open
              source
              securityprojecten. Basisbeveiliging gebruikt Nuclei voor het metingen van Login Plaza, maar het kan nog
              veel meer,
              maar dat mag niet publiek worden gepubliceerd. Daarom kan je de volledige scanner hier vinden.
            </p>
            <p>
              Wat kan je verwachten: De Nuclei scan doet een stuk of 2000 verzoeken naar de webserver en op diverse
              standaard poorten.
              Hiermee worden ongeveer 6000 soorten kwetsbaarheden getest: niet alleen welke login panels er zijn maar
              ook of e.e.a.
              verkeerd is geconfigureerd en of er diverse kwetsbaarheden te vinden zijn.
            </p>
            <p>De output van nuclei is in het JSONL formaat. Wij tonen dat op een eenvoudige manier in deze omgeving,
              maar het
              is mogelijk om dit rapport in te lezen in andere dashboards zoals OWASP Defect Dojo en Farraday.</p>
            <p>
              Meer over Nuclei staat op: <a href="https://nuclei.projectdiscovery.io/" target="_blank" rel="nofollow">https://nuclei.projectdiscovery.io/</a>
            </p>
          </b-tab>
          <b-tab title="Meer deelnemers, meer scanners...">
            Meer deelnemers van de stichting maakt meer mogelijk.
          </b-tab>
        </b-tabs>
      </b-collapse>
    </div>


    <h4>Noodzakelijke voorbereiding</h4>
    <full-scope-pre-test-checklist
      @compliant="preconditions_met = true; can_start()" @not_compliant="preconditions_met = false; can_start()"
      class="mb-4"
    />

    <div slot="modal-footer">
      <b-button variant="secondary" @click="$bvModal.hide('plan-full-scope-scan-modal')">
        {{ $t("cancel") }}
      </b-button>

      <b-button variant="success" class="ml-2" :disabled="!everything_set" @click="plan_full_scope_test()">
        {{ $t("ok") }}
      </b-button>
    </div>

  </b-modal>
</template>

<script>
import FullScopePreTestChecklist from "@/components/fullscopetests/FullScopePreTestChecklist";
import http from "@/httpclient";

export default {
  name: "PlanFullScanDialog",
  components: {FullScopePreTestChecklist},

  data() {
    return {
      selected_scanner: 'nuclei_full',
      options: [
        {
          value: 'nuclei_full',
          text: 'Nuclei, 2000 requests, 6000 checks (projectdiscovery.io)'
        },
        {value: null, text: 'Meer deelnemers, meer scanners...'},
      ],

      preconditions_met: false,
      everything_set: false,

      loading: false,
      server_response: {},
    }
  },

  methods: {
    can_start() {
      // console.log("Can start?")
      // console.log("Preconditions met: " + this.preconditions_met)
      // console.log("Selected scanner: " + this.selected_scanner)
      this.everything_set = this.preconditions_met && this.selected_scanner;
      // console.log("Everything set: " + this.everything_set)
    },

    plan_full_scope_test: function () {
      this.loading = true;
      http.post(`/fullscope/plan/`,
        {
          'url': this.$store.state.fullscopetest.url,
          'scanner': this.selected_scanner,
        }).then((data) => {
          this.server_response = data.data;
          this.loading = false;

          if (data.data.success) {
            this.$store.state.fullscopetest = {};
            this.$bvModal.hide('plan-full-scope-scan-modal');
          }
      });
    },
  }

}
</script>

<style scoped>

</style>
<i18n>
{
  "en": {
    "title": "Start Full Scope Test",
    "ok": "Let's go!",
    "cancel": "Cancel"
  },
  "nl": {
    "title": "Full Scope Test Starten",
    "ok": "Let's go!",
    "cancel": "Toch maar niet..."
  }
}

</i18n>
