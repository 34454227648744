<template>
  <div>

    <b-jumbotron bg-variant="success" text-variant="white">
      <template #header>
        <span style="font-size: 0.6em;">{{ $t("public_key") }}</span>
      </template>

      <template #lead>
        De publieke sleutel wordt gedeeld met basisbeveiliging. Met deze sleutel versleutelen wij gevoelige gegevens.
        <br><br>
        Na versleuteling zijn de gegevens alleen nog te lezen door ze te ontsleutelen met de <b>geheime sleutel</b>.
      </template>

      <b-input size="xl" v-model="public_key"/>

    </b-jumbotron>


    <b-button variant="primary" @click="generate_keypair" class="mr-2 mb-4">{{$t('generate')}}</b-button>

    <b-button variant="success" class="mr-2 mb-4" @click="save_public_key">{{ $t('save') }}  <span v-if="secret_key">{{$t('save_secret_first')}}</span></b-button>



    <server-response :response="server_response" v-if="server_response" :message="$t('' + server_response.message)"/>
    <loading :loading="loading"></loading>

      <b-jumbotron bg-variant="danger" text-variant="white" v-if="secret_key">
      <template #header>
        <span style="font-size: 0.6em;">{{ $t("secret_key") }}</span>
      </template>

      <template #lead>
        Nog zichtbaar: {{secret_key_visibility_countdown}} seconden.
        <br><br>

        Deel deze sleutel alleen met mensen die je vertrouwt. Deel deze nooit met basisbeveiliging, wij vragen nooit om deze sleutel en slaan deze ook niet op.
        <br><br>
        <b>Zonder deze sleutel is het onmogelijk om bestanden te ontsleutelen. (Tip: lees de vorige zin 3x).</b>
        <br><br>

        Het is aan te raden om regelmatig je sleutelset te vervangen. De oude rapporten blijven leesbaar met
        de oude sleutel.
      </template>

      <b-input size="xl" v-model="secret_key" disabled/>


    </b-jumbotron>

  </div>
</template>

<script>

import {box} from 'tweetnacl';
import {
  encodeBase64,

} from 'tweetnacl-util';

export default {
  name: "SetupPublicKeyForm",

  data: function () {
    return {
      public_key: "",
      secret_key: "",

      secret_key_visibility_countdown: 0,
      timer_set: false,
      timer_interval_listener: null,

      loading: false,
      server_response: {},
    }
  },

  mounted() {
    this.public_key = this.$store.state.user.account_public_key;
  },

  methods: {
    generate_keypair() {
      const kp = box.keyPair()
      this.public_key = encodeBase64(kp.publicKey);
      this.secret_key = encodeBase64(kp.secretKey);
      this.secret_key_visibility_countdown = 120;
      this.startTimer();
    },

    save_public_key() {
      this.secret_key = "";
      this.loading = true;
      this.$http.post('/account/set_public_key/', {'public_key': this.public_key}).then(data => {
        this.server_response = data.data;
        this.loading = false;

        if (this.server_response.success){
          let user_data = this.$store.state.user;
          user_data.account_public_key = this.public_key;
          this.$store.commit("set_user", user_data);
        }
      });

    },

    startTimer(duration) {
      if (this.timer_set)
        return

      let timer = this.secret_key_visibility_countdown;
      let self = this;

      this.timer_set = true;

      this.timer_interval_listener = setInterval(function () {
        self.secret_key_visibility_countdown -= 1;
        if (--timer < 0) {
          timer = duration;
          self.secret_key = ""
          self.timer_set = false;
          clearInterval(self.timer_interval_listener);
        }
      }, 1000);
    }
  }
}
</script>

<style scoped>

</style>
<i18n>
{
  "en": {
    "public_key": "Public Key",
    "secret_key": "Secret Key",
    "generate": "Create a new Public and Secret key",
    "save": "Save Public Key",
    "save_secret_first": "(this will remove the secret key from this page)",
    "public_key_set": "Public key set.",
    "public_key_not_valid": "Entered key is not valid. Try again."
  },
  "nl": {
    "public_key": "Publieke sleutel",
    "secret_key": "Geheime sleutel",
    "generate": "Maak nieuwe Publieke en Geheime sleutel",
    "save": "Publieke sleutel opslaan",
    "save_secret_first": "(dit verwijderd de geheime sleutel van deze pagina)",
    "public_key_set": "Publieke sleutel opgeslagen.",
    "public_key_not_valid": "Ingevoerde sleutel is niet geldig."
  }
}
</i18n>
