<template>
  <div>
    <h1>{{ $t("title") }}</h1>
    <p>{{ $t("intro") }}</p>

    <template v-if="show_retry">
      <b-button size="sm" class="m-2" type="submit" variant="secondary" @click="get_portfolio_organizations">
        <b-icon v-if="loading" icon="arrow-clockwise" animation="spin"></b-icon>
        {{ $t("Retry retrieval") }}
      </b-button>
    </template>

    <loading v-if="loading"></loading>

    <b-tabs content-class="mt-3">
      <template v-for="organization in organizations">
        <b-tab :title="organization.name" :key="organization.id" lazy>

          <b-tabs content-class="mt-3">
            <b-tab lazy>
              <template #title>
                <b-icon icon="hdd-network" variant="success"></b-icon> {{ $t("Resolving") }}
              </template>
              <portfolio_table :organization="organization" :resolves="1"></portfolio_table>
            </b-tab>
            <b-tab lazy>
              <template #title>
                <b-icon icon="wifi-off" variant="danger"></b-icon> {{ $t("Not resolving") }}
              </template>
              <p>{{$t('qouta')}}</p>
              <portfolio_table :organization="organization" :resolves="0"></portfolio_table>
            </b-tab>
            <b-tab lazy>
              <template #title>
                <b-icon icon="file-earmark-text" variant="warning"></b-icon> {{ $t("Report") }}
              </template>
              <p>{{$t('report_info')}}</p>

              <iframe :src="'https://basisbeveiliging.nl/report_participant/' + organization.id + '?skipheader=true&skipfooter=true&tab=risk_summary&skipplus=true'" allowfullscreen="true" style="height: 100vh" width="100%" name="bbv_report" />
              <br>

              <a :href="'https://basisbeveiliging.nl/report_participant/' + organization.id + '?skipheader=true&skipfooter=true&tab=risk_summary&skipplus=true'">Directe link naar rapport</a>


            </b-tab>
            <b-tab lazy>
              <template #title>
                <b-icon icon="building" variant="info"></b-icon> {{ $t("Administrative Data") }}
              </template>
              <OrganizationInformation :organization_id="organization.id"></OrganizationInformation>
            </b-tab>

          </b-tabs>

        </b-tab>
      </template>
    </b-tabs>

  </div>
</template>

<script>

import portfolio_table from "@/components/portfolio/portfolio_table";
import OrganizationInformation from "@/components/portfolio/OrganizationInformation";

export default {
  components: {
    OrganizationInformation,
    portfolio_table
  },
  data: function () {
    return {
      organizations: [],
      show_retry: false,
      loading: false,
    }
  },
  mounted: function () {
    this.get_portfolio_organizations();
  },
  methods: {
    get_portfolio_organizations: function () {
      this.server_response = {};
      this.loading = true;
      this.$http.get('/portfolio/list_organizations/').then(data => {
        this.organizations = data.data;
        this.loading = false;
        this.show_retry = false;
      }).catch(() => {
        this.show_retry = true;
      });
    }
  },
}
</script>
<i18n>
{
  "en": {
    "title": "Portfolio",
    "intro": "Insights in urls and planned scans. Every url in the list below is automatically rescanned periodically. Use the rescan button in case changes are made and you want a rescan as soon as possible.",
    "Retry retrieval": "Retry retrieval",
    "Resolving": "Resolving Domains",
    "Not resolving": "Not resolving Domains",
    "Administrative Data": "Administrative Data",
    "Report": "Report on basisbeveiliging",
    "quota": "Non-resolving urls do not count towards any quota. They might be shown in historic reports but will not be included in any future reports. To add a url to this list, make sure the domain DNS record is removed.",
    "report_info": "This is the report from basisbeveiliging.nl. This report is generated every night."
  },
  "nl": {
    "title": "Portfolio",
    "intro": "Inzicht in urls en geplande scans. Alle urls in onderstaande lijst worden regelmatig automatisch herscand. Gebruik de herscan-knop om dit te versnellen.",
    "Retry retrieval": "Probeer gegevens opnieuw op te halen",
    "Resolving": "Bereikbare domeinen",
    "Not resolving": "Niet bereikbare domeinen",
    "Administrative Data": "Administratieve gegevens",
    "Report": "Report op basisbeveiliging",
    "quota": "Domeinen die onbereikbaar zijn tellen niet mee in het verbruiksquota. Deze worden mogelijk getoond in historische rapporten. Deze lijst bestaat uit domeinen die uit de DNS zijn gehaald.",
    "report_info": "Dit is het rapport op basisbeveiliging.nl. Dit rapport wordt iedere nacht samengesteld."
  }
}

</i18n>
